import React from "react";
import CreatableSelect from "react-select/creatable";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useController } from "react-hook-form";
import { cn } from "../../../../helper/utils";

// export type OptionType = {
//     id: string | number;
//     value: string;
//     label: string;
// };
//
// type MultiSelectProps<T extends Record<string, any>> = {
//     selectProps?: Props;
//     options: Array<OptionType>;
//     className?: string;
//     width?: string;
//     icon?: React.ReactNode;
//     isHiddenIcon?: boolean;
//     controller: UseControllerProps<T>;
//     label?: string;
//     isRequired?: boolean;
//     height?: string;
//     placeholder?: string | React.ReactNode;
//     callbackOnchange?: (v) => void;
//     paddingTop?: "13px" | string; // 13 follow design , you can edit
//     paddingBottom?: "13px" | string; // 13 follow design , you can edit
//     isDisabled?: boolean;
// };

const CreateMultiSelectField = (props) => {
  const {
    selectProps,
    isDisabled,
    controller,
    options,
    label,
    placeholder = "",
    isRequired,
    callbackOnchange,
  } = props;
  const { field, fieldState } = useController(controller);
  const { error } = fieldState;

  const onchange = (v) => {
    field.onChange(v);
    callbackOnchange && callbackOnchange(v);
  };

  return (
    <>
      {label && (
        <legend className="text-gray-700 mb-2 text-sm font-medium">
          {label} {isRequired && <span className="text-red-500">*</span>}
        </legend>
      )}
      <CreatableSelect
        isDisabled={isDisabled}
        {...selectProps}
        placeholder={placeholder}
        options={options}
        value={field.value}
        onChange={onchange}
        className={cn("text-sm", {
          "border-red-500 border-solid border-[1px]": error,
        })}
      />
      {error && (
        <p className={error ? "text-red-600 text-xs" : ""}>{error.message}</p>
      )}
    </>
  );
};

export default CreateMultiSelectField;
