import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from '../../components/svgs/icons';

const NotFoundPage = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center bg-black/95">
      <div className="text-white text-center uppercase">
        <h1 className=' text-6xl font-[bold]'>404</h1>
        <h4>Page not found</h4>
        <div className="mt-4 flex flex-col gap-3 justify-center items-center">
          <ChevronDown fill='white' style={{ height: 20, width: 20 }} />
          <Link className='underline' to="/">Go to home page</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
