/** @format */

import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, InputField } from "../../components/commons";
import closeIcon from "../../../../assets/images/close-white.svg";
import playlistApi from "../../../../apis/api/playlist";
import unitApi from "../../../../apis/api/unit";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../../configuration/components/selectField";
import { useForm } from "react-hook-form";
import { reqGetListUnits } from "../../../../reduxs/cms/action";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CheckboxField from "../../configuration/components/checkboxField";

export const availabilityOptions = [
  { value: "available", name: "Availability" },
  { value: "releasing_soon", name: "Releasing soon" },
  { value: "sold", name: "Sold" },
  { value: "reserved", name: "Reserved" },
  { value: "multiple_offers", name: "Multiple offers" },
  { value: "leased", name: "Leased" },
];

export const facingOptions = [
  { value: "N", name: "N" },
  { value: "W", name: "W" },
  { value: "E", name: "E" },
  { value: "S", name: "S" },
  { value: "SW", name: "SW" },
  { value: "SE", name: "SE" },
  { value: "NW", name: "NW" },
  { value: "NE", name: "NE" },
]

export const unitTypeOptions = [
  { value: "A", name: "A" },
  { value: "B", name: "B" },
  { value: "C", name: "C" },
  { value: "D", name: "D" },
]

const validationSchema = yup.object().shape({
  name: yup
    .number()
    .required("Name is required")
    .typeError("Name must be a number"),
  level: yup
    .number()
    .required("Level is required")
    .typeError("Level must be a number"),
});

const UnitEditModal = ({ setShow, unitId, show }) => {
  const { units } = useSelector((state) => state.cms);
  const [playlists, setPlaylists] = useState([]);
  const dispatch = useDispatch();

  const unit = useMemo(
    () => units.find((unit) => unit.id === unitId) || {},
    [unitId, units]
  );
  console.log({unit})
  const getListPlaylists = async () => {
    const playlists = await playlistApi.getPlaylistList();
    setPlaylists(playlists.data);
  };

  const getListProperties = async () => {
    dispatch(
      reqGetListUnits({
        sortBy: JSON.stringify({
          name: 1,
        }),
      })
    );
  };

  useEffect(() => {
    getListPlaylists();
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      name: unit.name.split(" ")[1],
      availabilityStatus: unit.availabilityStatus
        ? {
            value: unit.availabilityStatus,
            name: availabilityOptions.find(
              (it) => it.value === unit.availabilityStatus
            ).name,
          }
        : null,
      level: unit.level,
      price: unit.price,
      bedrooms: unit.bedrooms,
      bathrooms: unit.bathrooms,
      size: unit.areaSqm,
      interior: unit.interior,
      exterior: unit.exterior,
      hasPool: unit.hasPool,
      facing: unit.facing
        ? {
          value: unit.facing,
          name: facingOptions.find(
            (it) => it.value === unit.facing
          ).name,
        }
      : null,
      unitType: unit.unitType
        ? {
          value: unit.unitType,
          name: unitTypeOptions.find(
            (it) => it.value === unit.unitType
          ).name,
        }
      : null,
    },
    resolver: yupResolver(validationSchema),
  });

  const handleEdit = async (data) => {
    try {
      const dirtyFields = Object.keys(formState.dirtyFields);
      const body = dirtyFields.reduce((acc, cur) => {
        if (["name", "level"].includes(cur)) {
          acc[cur] = cur === "name" ? "Unit " + data[cur] : data[cur];
        } else if (cur === "size") {
          acc["areaSqm"] = data[cur];
        } else if (['availabilityStatus', 'facing', 'unitType'].includes(cur)) {
          acc[cur] = data[cur].value
        }
        else {
          acc[cur] = data[cur];
        }
        return acc;
      }, {});
      const res = await unitApi.putUnitUpdate(unitId, body);
      if (res.data?.name) {
        toast.success("Update unit playlist successfully");
        return await getListProperties();
      }
      throw new Error(res.message);
    } catch (error) {
      toast.error(error.message ?? "Something's wrong");
    } finally {
      setShow(false);
    }
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[30px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      classCustom="overflow-hidden"
      wrapperClassName="wrap-update-content-modal"
      panelClassName="w-[40vw] max-w-2xl p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      title="Edit unit"
      content={
        <div className="bg-white rounded-lg relative">
          <div className="mb-5 flex justify-between">
            <InputField
              wrapperClassName="w-[49%]"
              required
              label="Unit number"
              controller={{ control, name: "name" }}
              inputProps={{ placeholder: "Please input unit number" }}
            />
            <InputField
              wrapperClassName="w-[49%]"
              required
              label="Level"
              controller={{ control, name: "level" }}
              inputProps={{ placeholder: "Please input unit level" }}
            />
          </div>
          <SelectField
            wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
            labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
            controller={{ control, name: "availabilityStatus" }}
            required
            label="Availability"
            options={availabilityOptions}
            placeholder="Please select availability status"
          />
          <SelectField
            wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
            labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
            controller={{ control, name: "facing" }}
            required
            label="Facing"
            options={facingOptions}
            placeholder="Please select facing"
          />
          <SelectField
            wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
            labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
            controller={{ control, name: "unitType" }}
            required
            label="Unit type"
            options={unitTypeOptions}
            placeholder="Please select unit type"
          />
          <div className="mb-5 flex justify-between">
            <InputField
              wrapperClassName="w-[49%]"
              required
              label="Price"
              controller={{ control, name: "price" }}
              inputProps={{ placeholder: "Please input price" }}
            />
            <InputField
              wrapperClassName="w-[49%]"
              required
              label="Size"
              controller={{ control, name: "size" }}
              inputProps={{ placeholder: "Please input size" }}
            />
          </div>
          <div className="mb-5 flex justify-between">
            <InputField
              wrapperClassName="w-[49%]"
              required
              label="Interior"
              controller={{ control, name: "interior" }}
              inputProps={{ placeholder: "Please input interior" }}
            />
            <InputField
              wrapperClassName="w-[49%]"
              required
              label="Exterior"
              controller={{ control, name: "exterior" }}
              inputProps={{ placeholder: "Please input exterior" }}
            />
          </div>
          <div className="mb-5 flex justify-between">
            <InputField
              wrapperClassName="w-[49%]"
              required
              label="Bedroom"
              controller={{ control, name: "bedrooms" }}
              inputProps={{ placeholder: "Please input bedrooms" }}
            />
            <InputField
              wrapperClassName="w-[49%]"
              required
              label="Bathroom"
              controller={{ control, name: "bathrooms" }}
              inputProps={{ placeholder: "Please input bathrooms" }}
            />
          </div>
          <CheckboxField label="Pools" id="pool" type="checkbox" controller={{ control, name: "hasPool" }}/>
          <div className="flex gap-3 mt-8">
            <Button
              disabled={formState?.isSubmitting}
              variant="text"
              onClick={() => setShow(false)}
              className="flex-1"
            >
              Cancel
            </Button>
            <Button
              disabled={formState?.isSubmitting || !formState?.isDirty}
              isLoading={formState?.isSubmitting}
              onClick={handleSubmit(handleEdit)}
              className="bg-yellow-200 text-black hover:bg-yellow-300 hover:text-black disabled:hover:bg-yellow-200 disabled:hover:text-black flex-1"
            >
              Save
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default UnitEditModal;
