/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Modal } from "../../components/commons";
import closeIcon from "../../../../assets/images/close-white.svg";
import { toast } from "react-toastify";
import mediaAPI from "../../../../apis/api/media";
import playListApi from "../../../../apis/api/playlist";
import * as yup from "yup";
import uploadIcon from "../../../../assets/images/upload.svg";
import { getImageResolution, MEDIA_TYPE } from "../../utils";

import SelectField from "../../configuration/components/selectField";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { reqGetListFolder } from "../../../../reduxs/cms/action";

const playListTypeOptions = [
  { id: 1, value: MEDIA_TYPE.IMAGE, name: "Images" },
  { id: 5, value: MEDIA_TYPE.FLOORPLANS, name: "Floorplans" },
  { id: 7, value: MEDIA_TYPE.VIEWLINES, name: "Viewlines" },
  { id: 8, value: MEDIA_TYPE["360IMAGE"], name: "360 Images" },
];

export const AddUnitMediaModal = ({
  show,
  setShow,
  onCreateSuccess,
  playlistId,
}) => {
  const [files, setFiles] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const [isShowLoading, setShowLoading] = useState(false);
  const assets = useSelector((state) => state.cms.folders) || [];
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const { watch, control } = useForm({
    defaultValues: {
      mediaType: playListTypeOptions[0],
    },
  });

  useEffect(() => {
    if (assets.length) {
      return;
    }
    dispatch(reqGetListFolder());
  }, [assets]);

  const mediaType = watch("mediaType")?.value;

  const handleFileSelect = (e, forVideo = false) => {
    const selectedFiles = Array.from(e.target.files);
    setErrors({});
    setFiles(selectedFiles);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDragOver = (e, forVideo) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e, forVideo) => {
    setIsDragging(false);
  };

  const handleDrop = (e, forVideo = false) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (!droppedFiles[0].type.startsWith("image")) {
      return setErrors({ ...errors, file: "Invalid file type" });
    } else {
      setErrors({ ...errors, file: "" });
      return setFiles(droppedFiles);
    }
  };

  const renderImagePreview = () => {
    if (files.length) {
      return URL.createObjectURL(files[0]);
    }
  };

  const addImgMediaPlayList = async () => {
    setShowLoading(true);
    try {
      for (const file of files) {
        const mediaTitle = assets.find(
          (asset) => asset.value === mediaType
        ).label;

        if (mediaType === MEDIA_TYPE.PARONAMIC) {
          const { width, height } = await getImageResolution(file);

          if (width / height < 3) {
            toast.error(`File ${file.name} is not a ${mediaTitle} asset.`);
            return;
          }
        }

        if (mediaType === MEDIA_TYPE["360IMAGE"]) {
          const { width, height } = await getImageResolution(file);

          // 2:1 aspect ratio equirectangular
          if (width / height !== 2) {
            toast.error(`File ${file.name} is not a ${mediaTitle} asset.`);
            return;
          }
        }

        const uploaded = await mediaAPI.uploadMedia(file);
        if (uploaded.message) {
          throw new Error(uploaded.message);
        }

        if (uploaded?.data) {
          let params = {
            isActive: true,
            path: uploaded?.data?.path,
            thumbnailPath: uploaded?.data?.thumbnailPath,
            type: mediaType,
            fileName: file?.name,
            name: file?.name,
            playlistId,
          };
          const res = await playListApi.createMediaPlaylist(params);
          if (res && res?.data) {
          }
        }
      }
      toast.success("Add new content successfully!");
      handleClose();
      onCreateSuccess?.();
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      } else {
        toast.error(err.message);
      }
    } finally {
      setShowLoading(false);
    }
  };

  const onChangeType = () => setFiles([]);

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      isLoading={isShowLoading}
      wrapperClassName="wrap-update-content-modal"
      panelClassName="w-[630px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] max-h-[90vh] ">
          <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
            <div>
              <div>
                <div className="flex justify-between flex-wrap">
                  <SelectField
                    wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000]"
                    labelClassName="text-[14px] w-full text-[#5d5d5d] mb-[10px] font-[500]"
                    controller={{ control, name: "mediaType" }}
                    required
                    label="Media Type"
                    options={playListTypeOptions}
                    callbackOnChange={onChangeType}
                    placeholder="Please select type of playlist"
                  />
                  <button onClick={handleClose} className="underline">
                    Close
                  </button>
                </div>
              </div>
              <div
                className={`relative min-h-[330px] flex flex-col justify-center mt-4 mb-4 border-dashed border-2 border-gray-400 p-4 rounded-md ${
                  isDragging ? "bg-gray-200" : "bg-white"
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                {!isEmpty(files) && (
                  <img
                    className="absolute top-0 left-0 w-full img-fluid cursor-pointer h-[325px] object-cover"
                    src={renderImagePreview()}
                    alt=""
                  />
                )}
                <input
                  type="file"
                  multiple
                  className="hidden"
                  id="file-upload"
                  onChange={handleFileSelect}
                  accept={".png, .webp"}
                />
                <label htmlFor="file-upload" className="cursor-pointer">
                  <div className="text-center flex flex-col items-center">
                    <p className="text-gray-600">
                      Drag & drop your files here or click to upload
                    </p>
                    {!isEmpty(files) && (
                      <img
                        src={uploadIcon}
                        alt="Upload"
                        className="w-20 h-20 relative"
                      />
                    )}
                    {files.length > 0 && (
                      <ul className="mt-4">
                        {files.map((file, index) => (
                          <li key={index} className="text-sm text-gray-800">
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </label>
              </div>
              <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                {errors?.file}
              </span>
            </div>
            <div
              className="font-poppins cursor-pointer text-[14px] font-[700] text-[#000] mt-[20px] px-[18px] py-[7px] uppercase border-solid border-[1px] border-[#000] w-full text-center"
              onClick={addImgMediaPlayList}
            >
              SAVE
            </div>
          </div>
        </div>
      }
    />
  );
};
