import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import authApi from "../../apis/api/auth";
import ROUTE_PATH from "../../routers/path";
import FormInput from './form-input';
import { toast } from "react-toastify";

const FormForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      setErrorMessage("")
    }
  }, [email]);

  const handleForgotPassword = async () => {
    const data = {
      email: email,
    };

    const res = await authApi.forgotPassword(data);

    if (res?.data) {
      setErrorMessage("")
      toast.success("Reset password email sent")
    } else {
      setErrorMessage("Email not found");
    }
  };

  const handleLogin = () => {
    navigate(ROUTE_PATH.HOLDING_PAGE);
  }

  return (
    <div className="flex flex-col justify-center w-[534px]">
      <FormInput
        type="email"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        id="email"
        placeholder="Email"
      />
      {
        errorMessage &&
        <p id="passwordHelp" className="text-red-500 mt-2">
          {errorMessage}
        </p>
      }
      <div className="bg-button-bottom">
        <button
          type="submit"
          onClick={handleForgotPassword}
          className="flex justify-center items-center border-none bg-black w-full h-[60px] mt-[25px] mb-6"
        >
          <span className="text-white text-center font-sans text-sm font-normal leading-normal uppercase tracking-[2.25px]">
            ENTER
          </span>
        </button>
      </div>
      <span
        onClick={handleLogin}
        className="link-btn text-[#242422] text-center font-sans font-normal text-sm leading-7 uppercase h-5 cursor-pointer tracking-widest hover:underline"
      >
        LOGIN
      </span>
    </div>
  );
};

export default FormForgotPassword;
