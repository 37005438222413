import TopNavCMS from "./cms-top-nav";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reqGetPageList } from "../../reduxs/page-configuration/action";

const CMS = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authMiddleware } = props;

  useEffect(() => {
    authMiddleware(navigate);
    dispatch(reqGetPageList({}));
  }, []);

  return (
    <div className="min-h-[100dvh] bg-[#fff]">
      <TopNavCMS />
      {props.children}
    </div>
  );
};
export default CMS;
