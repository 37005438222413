/** @format */

import React, { useEffect } from "react";
import {
  ACTION_NAME,
  REACTUI_PAGES,
  WEBSOCKET_CHANNEL,
} from "../../constants/options";
import { useState } from "react";
import LoadingIcon from "../loading-icon";
import { useDispatch, useSelector } from "react-redux";
import { reqSetPage } from "../../reduxs/home/action";
import { reqGetUserProfile } from "../../reduxs/user/action";
import socket from "../../helper/socket";
import Profile from "./profile";
import { useNavigate } from "react-router-dom";
import { reqGetListAgents } from "../../reduxs/cms/action";
import { getS3BEMediaUrl, getS3FEMediaUrl } from "../../helper/media";
import { ReactComponent as Vector } from "../../assets/images/vector.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Loading = (props) => {
  const { isLoading, isPresentation } = props;
  const [isShowVideo, setShowVideo] = useState(false);
  const authUser = useSelector((state) => state.user.data);
  const agents = useSelector((state) => state.cms.agents);
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.user.customer);
  const [isShowProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const logo = activeConfig?.theme?.cssVariables?.find(
    (variable) => variable.name === "logo"
  )?.value;
  const currentIntro = activeConfig?.theme?.intro;
  const [urlBg, setUrlBg] = useState();
  const [urlVideo, setUrlVideo] = useState();

  useEffect(() => {
    dispatch(reqGetUserProfile());
    dispatch(reqGetListAgents());
  }, []);

  useEffect(() => {
    const urlBg = currentIntro?.thumbnailPath
      ? getS3BEMediaUrl(currentIntro?.thumbnailPath)
      : getS3FEMediaUrl("/poster.jpg");
    const urlVideo = currentIntro?.path
      ? getS3BEMediaUrl(currentIntro?.path)
      : getS3FEMediaUrl("/introduce.mp4");
    setUrlBg(urlBg);
    setUrlVideo(urlVideo);
  }, [currentIntro]);

  useEffect(() => {
    if (authUser) {
      analytics.identify(authUser.id, {
        name: authUser.name,
        email: authUser.email,
      });
    }
  }, [authUser]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.START_SESSION) {
      onStartSession(content.data.userId);
    }
    if (content.action === ACTION_NAME.SKIP_SESSION_VIDEO) {
      startDiscover();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const startDiscover = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SKIP_SESSION_VIDEO);
    }
    dispatch(reqSetPage(REACTUI_PAGES.LANDING_PAGE));
    analytics.track("Agent Started Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientName: customer?.name,
      clientEmail: customer?.email,
      clientPhone: customer?.phone,
    });
  };

  const onExplore = () => {
    setShowProfile(true);
  };

  const onStartSession = (userId) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.START_SESSION, { userId });
    } else {
      navigate({
        search: `?customer=${userId}`,
      });
    }
    // dispatch(reqSetIsShowReplayVideo(true));
    dispatch(reqSetPage(REACTUI_PAGES.LANDING_PAGE));
    // setShowVideo(true);
    setShowProfile(false);
    // if (videoRef.current) {
    //   if (isPresentation) {
    //     videoRef.current.muted = true;
    //   }
    //   videoRef.current?.play();
    // }
  };

  const renderExploreButton = () => {
    if (!authUser) return;

    return (
      <button
        className="flex items-center bg-transparent text-brand p-0 pb-1.25 text-center font-sans text-sm not-italic font-normal leading-normal tracking-widest uppercase w-auto border-b border-[var(--brand-text-color)]"
        onClick={() => onExplore()}
      >
        EXPLORE THE TOWERS
        <Vector className="w-[32px] h-[10px] ml-[10px] mb-[4px] mt-0 *:fill-[var(--brand-text-color)]" />
      </button>
    );
  };

  const handleCancel = () => {
    setShowProfile(false);
  };
  //disable-btn

  return (
    <div
      id="loading-screen"
      className="absolute top-0 left-0 w-screen h-screen overflow-hidden opacity-100 transition-opacity duration-1000 flex items-end text-white text-center bg-black"
    >
      <div
        id="loading-cube-wrapper"
        className="absolute top-6 left-1/2 transform -translate-x-1/2"
      ></div>
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
        <div className={`wrap-intro h-full ${isShowVideo ? "hide" : "show"}`}>
          {!isShowProfile ? (
            <div className="h-full">
              <video
                autoPlay
                muted
                loop
                preload="none"
                id="intro-video"
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-cover z-10 bg-center bg-cover"
                style={{
                  backgroundImage: `url("${urlBg}")`,
                }}
                src={urlVideo}
              />
              <div className="w-full h-full flex flex-col justify-around items-center pb-0 relative z-20">
                <div></div>
                {logo && (
                  <LazyLoadImage
                    src={getS3BEMediaUrl(logo)}
                    alt="bay-harbor-tower"
                    className="max-w-[291px] mt-[-100px]"
                  />
                )}
                <div className="h-[165px] flex flex-col justify-center items-center">
                  <div className="h-[102px] w-[102px] relative">
                    {!!isLoading && <LoadingIcon />}
                  </div>
                  {!isLoading && (
                    <div
                      className={`mt-[-170px] flex items-center bg-[#ffffff22] p-3 rounded-lg`}
                    >
                      {renderExploreButton()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Profile
              startDiscover={onStartSession}
              handleCancel={handleCancel}
              agents={agents}
              isPresentation={isPresentation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Loading;
