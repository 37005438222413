import React from 'react';
import { useSelector } from "react-redux";
import { getS3BEMediaUrl } from '../../helper/media';

const HoldingPageWrapper = ({
  children,
}) => {
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const logo = activeConfig?.theme?.cssVariables?.find(
    (variable) => variable.name === "logo"
  )?.value;

  return (
    <div className="flex justify-center items-center h-full bg-white">
      <div className={`text-sm w-[666px] px-10 py-9 shadow-none flex flex-col items-center text-black`}>
        <div className="flex justify-center w-11/12 pb-[75px] border-b border-b-[rgba(36,36,34,0.15)]">
          {logo && <img src={getS3BEMediaUrl(logo)} className="w-72 h-64" />}
        </div>
        <div className="mt-12">
          {children}
        </div>
      </div>
    </div>
  );
};

export default HoldingPageWrapper;
