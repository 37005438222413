/** @format */

import React, { useMemo } from "react";
import { InfoWindow, Marker } from "@react-google-maps/api";
import { COMPONENTS_ENUM } from "../../../../constants/modules";
import { useSelector } from "react-redux";
import { getS3BEMediaUrl } from "../../../../helper/media";

const markerIconSVG =
  '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><rect x="0.707107" y="10.6064" width="14" height="14" transform="rotate(-45 0.707107 10.6064)" fill="white" stroke="black"/><rect x="5.30273" y="10.6064" width="7.5" height="7.5" transform="rotate(-45 5.30273 10.6064)" fill="black"/></svg>';
const activeMarkerIconSVG =
  '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><rect x="0.707107" y="10.8193" width="14" height="14" transform="rotate(-45 0.707107 10.8193)" fill="black" stroke="black"/><rect x="5.30273" y="10.8193" width="7.5" height="7.5" transform="rotate(-45 5.30273 10.8193)" fill="#E4FFFD"/></svg>';

function svgIconToURL(svg) {
  return "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg);
}

const markerIcon = {
  url: svgIconToURL(markerIconSVG),
};

const activeMarkerIcon = {
  url: svgIconToURL(activeMarkerIconSVG),
};

const MapMarker = (props) => {
  const {
    poi,
    activeCategory,
    activePoi,
    activeMarkers,
    handleClickPoint,
    visible = false,
  } = props;

  if (!poi.action_types.includes("marker")) return <></>;
  if (!visible || (activeCategory && poi.category !== activeCategory)) {
    return <></>;
  }

  const activeConfig = useSelector((state) => state.cms.activeConfig);

  const logo = useMemo(() => {
    const path = activeConfig?.theme?.cssVariables.find(
      (it) => it.name === "logo-location"
    );

    return path?.value ? getS3BEMediaUrl(path.value) : undefined;
  }, [activeConfig]);

  return (
    <Marker
      key={`${poi.id}_marker`}
      position={{
        lat: poi.marker.coordinates.lat,
        lng: poi.marker.coordinates.lng,
      }}
      icon={{
        ...markerIcon,
        clickable: true,
        scaledSize: !poi.marker.icon
          ? new google.maps.Size(35, 35)
          : new google.maps.Size(40, 40),
        path: poi.marker.path ? poi.marker.path : markerIcon.path,
        url: poi.marker.icon
          ? logo || svgIconToURL(poi.marker.icon)
          : poi.id === activePoi || poi.category === activeCategory
          ? activeMarkerIcon.url
          : markerIcon.url,
        anchor: !poi.marker.icon
          ? new google.maps.Point(13.5, 13.5)
          : undefined, // anchor 0 displaced the big icon
      }}
      onClick={(e) => {
        handleClickPoint(poi);
      }}
      onLoad={(e) => {
        activeMarkers.push(e.getPosition());
      }}
      onUnmount={(e) => {
        let lat = e.getPosition().lat();
        let lng = e.getPosition().lng();
        activeMarkers.splice(
          activeMarkers.findIndex((am) => {
            return am.lng() === lng && am.lat === lat;
          }, 1)
        );
      }}
      data={poi}
      zIndex={poi.id === activePoi || poi.category === activeCategory ? 9 : 1}
    >
      {activePoi === poi.id && poi.action_types.includes("info_window") && (
        <InfoWindow
          key={`${poi.id}_info_window`}
          onCloseClick={clearActivePoi}
          position={{
            lat: poi.marker.coordinates.lat,
            lng: poi.marker.coordinates.lng,
          }}
          options={{ closeBoxMargin: "100px 20px 2px 2px" }}
        >
          <div dangerouslySetInnerHTML={{ __html: poi.info_window.content }} />
        </InfoWindow>
      )}
    </Marker>
  );
};
MapMarker.displayName = COMPONENTS_ENUM.MAP_POI_MARKER;

export default MapMarker;
