import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cmsOptions } from "../../routers/options";
import { doesUserGroupsHaveRoute } from "./utils";

const CMSDefaultList = () => {
  const navigate = useNavigate();

  const authUser = useSelector((state) => state.user.data);

  const userAllowedCmsOptions = useMemo(() => {
    return cmsOptions.filter((route) => {
      if (!!route.nav && doesUserGroupsHaveRoute((authUser?.userGroups || []), route.key)) {
        return true;
      }
    });
  }, [authUser]);

  return (
    <div className="bg-white py-20 sm:py-24">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base/7 font-semibold text-black text-center">FRAMEWORK</h2>
        <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance text-center">
          CMS Portal
        </p>
      </div>
      <div className="mx-auto mt-12 max-w-2xl sm:mt-12 lg:mt-16 lg:max-w-3xl">
        <div className="max-h-[50vh] overflow-hidden overflow-y-auto sm:ml-10 grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 sm:max-w-3xl sm:grid-cols-2 lg:max-w-3xl lg:grid-cols-3 lg:gap-y-16">
          {userAllowedCmsOptions.map((option) => (
            <div key={option.key} className="relative pl-12">
              <div
                onClick={() => navigate(`/cms/${option.path}`)}
                className="text-base/7 leading-8 font-semibold text-gray-900 hover:underline cursor-pointer"
              >
                <div className="absolute left-0 top-0 flex size-8 items-center justify-center rounded-lg border-2 border-black">
                  <option.ic aria-hidden="true" className="size-4 text-black" />
                </div>
                {_.startCase(_.camelCase(option.key))}
              </div>
              {/* <dd className="mt-2 text-base/7 text-gray-600">{feature.description}</dd> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  );
};

export default CMSDefaultList;
