/** @format */

import socket from "../../helper/socket";
import React, { useEffect, useRef, useState } from "react";
import icBayHarbor from "../../assets/images/logo-bay-harbor.png";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { PAGES_ENUM, MODULES_ENUM } from "../../constants/modules";
import { useSelector } from "react-redux";
import { getS3BEMediaUrl } from "../../helper/media";
import _ from "lodash";

const LEFT = "left-image";
const MULTI_LEFT = "multi-left-image";

const Item = ({ tag, textLeft, title, description, media }) => {
  return (
    <div
      className="content-div flex min-h-[768px] justify-center w-screen scroll-mt-[100px] border-b-[rgba(36,36,34,0.149)] border-b border-solid bg-brand"
      id={tag}
    >
      <div className="flex flex-col items-center min-h-[768px] justify-center gap-10 self-stretch w-full">
        <div
          className={`items-stretch flex flex-row justify-between w-full py-10 ${
            !textLeft ? "pl-[88px] pr-10" : "flex-row-reverse pl-10 pr-[88px]"
          }`}
        >
          <div
            className={`relative w-[40%] before:bg-[rgba(36,36,34,0.149)] ${
              textLeft
                ? 'before:content-[""] before:block before:h-[calc(100%_+_80px)] before:absolute before:w-px before:-left-10 before:-top-10'
                : 'after:bg-[rgba(36,36,34,0.149)] after:content-[""] after:block after:h-[calc(100%_+_80px)] after:absolute after:w-px after:-right-10 after:-top-10'
            }`}
          >
            <div className="flex items-center mt-5 mb-[60px]">
              <span className="w-2 h-2 bg-primary inline-block mr-[15px] rounded-[50%]" />
              <p className="font-normal leading-[14px] text-xs text-primary uppercase m-0 pt-0.5 font-sans">
                {tag}
              </p>
            </div>
            <div className="font-sans text-[52px] font-normal leading-[50px] uppercase text-primary">
              {title}
              <br />
            </div>
            <div className="flex flex-col ml-0 mb-0 pt-8 pb-0 px-0">
              <span className="whitespace-pre-line text-base font-normal leading-6 text-secondary font-sans">
                {description}
              </span>
            </div>
          </div>
          <div className="w-[50%]">
            <img
              src={getS3BEMediaUrl(media?.path)}
              className="h-[688px] object-cover w-[100%]"
              alt="developer"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Ppg = (props) => {
  const { isPresentation } = props;
  const pageRef = useRef(null);
  const [visibleDivId, setVisibleDivId] = useState("developer-section");
  const idRef = useRef("developer-section");
  const pages = useSelector((state) => state.configuration.pages);
  const [contents, setContents] = useState([]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };

  useEffect(() => {
    if (pages) {
      pages.map((items) => {
        if (items.name === PAGES_ENUM.STATIC_PAGE) {
          items.modules?.map((i) => {
            if (i?.props?.type === "template-2") {
              setContents(i?.props.sections);
            }
          });
        }
      });
    }
  }, [pages]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  const onNavClick = (id) => {
    const section = document.getElementById(`${id}`);
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    const container = document.getElementById("ppg-page");
    function isInContainerViewport(container, element) {
      let containerRect = container.getBoundingClientRect();
      let elementRect = element.getBoundingClientRect();
      return (
        elementRect.top >= containerRect.top &&
        elementRect.top <= containerRect.top + container.clientHeight * 0.3
      );
    }

    function isBottomReached(container) {
      return (
        container.scrollTop + container.clientHeight >= container.scrollHeight
      );
    }

    function handleScroll() {
      let divs = container.querySelectorAll(".content-div");

      if (isBottomReached(container)) {
        idRef.current = "branding-section";
        setVisibleDivId("branding-section");
        return;
      }

      divs.forEach(function (div) {
        if (isInContainerViewport(container, div)) {
          const id = div.id;
          if (id && idRef.current !== id) {
            idRef.current = id;
            setVisibleDivId(id);
          }
        }
      });
    }

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // if (!props?.moduleConfig?.props.visible) {
  //   return <div />;
  // }

  return (
    <div
      ref={pageRef}
      onScroll={onScroll}
      id="ppg-page"
      className={`h-[100dvh] overflow-y-scroll z-[3] bg-[#fcfcfc] absolute overflow-x-hidden w-screen my-0 pb-[49px] top-0 ${
        isPresentation && "presentation"
      }`}
    >
      <div
        className={`items-center bg-brand text-brand flex justify-between fixed w-full z-[2] ${
          isPresentation ? "top-0" : "top-12"
        }`}
      >
        {contents.map((item, idx) => {
          if (idx !== 0) {
            return (
              <div
                key={_.uniqueId(`${item?.tag}`)}
                onClick={() => onNavClick(item.tag)}
                className={
                  item.tag === visibleDivId
                    ? "h-[51px] basis-full flex justify-center items-center border text-xs font-normal relative cursor-pointer border-r-0 border-t-[unset] border-solid border-brand font-sans before:absolute before:bg-[black] before:h-1 before:w-1  before:content-[attr(before)] before:rounded-[100%] before:bottom-2.5"
                    : "h-[51px] basis-full flex justify-center items-center border text-xs font-normal relative cursor-pointer border-r-0 border-t-[unset] border-solid border-brand font-sans"
                }
              >
                {item.tag}
              </div>
            );
          }
        })}
      </div>
      <div className="flex w-screen justify-center h-[700px] scroll-mt-[100px] border-b-[#24242226] border-b border-solid bg-brand">
        <div className="items-center text-black flex flex-col justify-center pl-[233.5px] pr-[232.5px] pt-[300px] pb-[165.366px] w-[1366px]">
          <div className="items-center flex flex-col text-center mb-10">
            <div className="text-primary text-[75px] not-italic font-normal leading-[120%] uppercase font-domaine">
              {contents[0]?.title}
            </div>
          </div>
          <div className="w-10 h-[36.634px] bg-transparent">
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              alt="icBayHarbor"
              src={icBayHarbor}
            />
          </div>
        </div>
      </div>
      {contents.map((item, i) => {
        if (i !== 0) {
          return (
            <Item
              key={_.uniqueId(`${item?.type}`)}
              {...item}
              textLeft={item?.type === LEFT || item?.type === MULTI_LEFT}
            />
          );
        }
      })}
      {!isPresentation && <div className="bottom-top" />}
    </div>
  );
};

Ppg.displayName = MODULES_ENUM.TEAM;
Ppg.pageGroup = PAGES_ENUM.PPG_PAGE;
export default Ppg;
