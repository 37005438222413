import ApiClient from "../apiClient";
import { authFetcher, defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";
import { blobToWebP } from "webp-converter-browser";

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getMediaList = (data) => client.get(endPoint.MEDIA_GET_LIST, data);
const getMediaDetail = (mediaId, data) =>
  client.get(endPoint.MEDIA_GET_DETAIL.replace(":mediaId", mediaId), data);
const createMedia = (data) => defaultClient.post(endPoint.MEDIA_GET_LIST, data);

const getMediaBulUnitOptions = () =>
  defaultClient.get(endPoint.BULK_UNIT_MEDIA_GET_LIST);

const updateBulkUnitMedia = (data) =>
  defaultClient.put(endPoint.BULK_UNIT_MEDIA_UPDATE, data);

const uploadMedia = (file) =>
  new Promise(async (resolve, reject) => {
    try {
      // Convert file to WebP using blobToWebP
      const webpBlob = await blobToWebP(file, {
        quality: 0.8, // 0 to 1
      });

      if (!webpBlob) {
        throw new Error("Failed to convert to WebP");
      }

      // Create new file with .webp extension
      const imageRegex = new RegExp(/\.(png|jpe?g|webp)$/i);
      const imageFile = new File(
        [webpBlob],
        file.name.replace(imageRegex, ".webp"),
        {
          type: "image/webp",
        }
      );

      // Upload the converted file
      const formData = new FormData();
      formData.append("file", imageFile);
      const result = await defaultClient.uploadFormData(
        endPoint.MEDIA_UPLOAD,
        formData
      );
      resolve(result);
    } catch (error) {
      console.error("WebP conversion failed:", error);

      // Fallback: upload original file if conversion fails
      try {
        const formData = new FormData();
        formData.append("file", file);
        const result = await defaultClient.uploadFormData(
          endPoint.MEDIA_UPLOAD,
          formData
        );
        resolve(result);
      } catch (uploadError) {
        reject(uploadError);
      }
    }
  });
const uploadMediaVideo = (data) =>
  defaultClient.uploadFormData(endPoint.MEDIA_VIDEO_UPLOAD, data);
const uploadMediaModel = (data) =>
  defaultClient.uploadFormData(endPoint.MEDIA_MODEL_UPLOAD, data);
const updateMedia = (id, data) =>
  defaultClient.put(endPoint.MEDIA_UPDATE.replace(":id", id), data);
const deleteMedia = (id) =>
  defaultClient.delete(endPoint.MEDIA_DELETE.replace(":id", id));

export default {
  getMediaList,
  getMediaDetail,
  getMediaBulUnitOptions,
  uploadMedia,
  uploadMediaVideo,
  uploadMediaModel,
  updateMedia,
  deleteMedia,
  createMedia,
  updateBulkUnitMedia,
};
