import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from '../../components/svgs/icons';

const ForbiddenCMSPage = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center bg-white/95">
      <div className="text-black text-center uppercase">
        <h1 className=' text-6xl font-[bold]'>403</h1>
        <h4>Forbidden</h4>
        <div className="mt-4 flex flex-col gap-3 justify-center items-center">
          <ChevronDown fill='black' style={{ height: 20, width: 20 }} />
          <Link className='underline' to="/cms">Go to CMS Dashboard</Link>
        </div>
      </div>
    </div>
  );
};

export default ForbiddenCMSPage;
