/** @format */

import ApiClient from "../apiClient";
import { defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const defaultClient = new ApiClient(defaultFetcher);

const getListAccessRules = () => defaultClient.get(endPoint.ACCESS_RULES);
const getListUserGroup = () => defaultClient.get(endPoint.USER_GROUP);
const createUserGroup = (query) =>
  defaultClient.post(endPoint.USER_GROUP, query);
const deleteUserGroup = (id) =>
  defaultClient.delete(endPoint.USER_GROUP_ID.replace(":id", id));
const editUserGroup = (id, query) =>
  defaultClient.put(endPoint.USER_GROUP_ID.replace(":id", id), query);
const getDetailUserGroup = (id) =>
  defaultClient.get(endPoint.USER_GROUP_ID.replace(":id", id));

export default {
  getListAccessRules,
  getListUserGroup,
  createUserGroup,
  deleteUserGroup,
  editUserGroup,
  getDetailUserGroup,
};
