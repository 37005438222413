import { Vector3 } from "three";
import ReactUI from "../reactui";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setColor } from "../../helper/threeHeper";
import React, { useEffect, useRef, useState } from "react";
import CanvasBox from "../../components/3d-scene/CanvasBox";
import { orbitConfigTemplate } from '../Index';

const RefCanvasBox = React.forwardRef((props, ref) => (
  <CanvasBox ref={ref} {...props} />
));
RefCanvasBox.displayName = "RefCanvasBox";

const Presentation = (props) => {
  const [orbitConfig, setOrbitConfig] = useState({ ...orbitConfigTemplate });
  const { authMiddleware } = props;
  const [setIsIntroduction] = useState(false);
  const controls = useRef();
  let refScene = useRef();
  const navigate = useNavigate();
  const activeObjectIds = useRef([]);

  useEffect(() => {
    authMiddleware(navigate);
  }, []);

  async function setActiveObjectIds(ids) {
    let prevIds = [];
    for (const index in activeObjectIds.current) {
      if (!ids.includes(activeObjectIds.current[index])) {
        prevIds.push(activeObjectIds.current[index]);
      }
    }
    activeObjectIds.current = ids;
    await handleResetObjectColor(prevIds);
    handleSetActiveObjectColor(ids);
  }

  function resetActiveColorModel(model) {
  }

  const handleResetObjectColor = async (prevIds = []) => {
    refScene.current &&
      (prevIds || []).forEach((item) => {
        let objectSelector = refScene.current.getObjectByName(item);
        if (objectSelector) {
          setColor(
            objectSelector.userData.color,
            objectSelector.userData.active_alpha,
            objectSelector,
            objectSelector.userData.emissive,
          );
          objectSelector.userData.isActive = false;
        }
      });
  };

  const handleSetActiveObjectColor = (ids) => {
    refScene.current &&
    (ids || [])?.forEach((item) => {
      let objectSelector = refScene.current.getObjectByName(item);
        if (objectSelector) {
          setColor(
            objectSelector.userData.active_color,
            objectSelector.userData.active_alpha,
            objectSelector,
            objectSelector.userData.active_emissive,
          );
          objectSelector.userData.isActive = true;
        }
      });
  };

  return (
    <>
      {
        !props.isLoading &&
        <CanvasBox
          orbitConfig={orbitConfig}
          setOrbitConfig={setOrbitConfig}
          orbitConfigTemplate={orbitConfigTemplate}
          isPresentation={true}
          ref={refScene}
          controls={controls}
          objects={props.meshes}
          hotspots={props.hotspots}
          activeObjectIds={activeObjectIds.current}
          setActiveObjectIds={setActiveObjectIds}
          _3dSetting={props.settings}
          fbxs={props.fbxs}
          pagesSettings={props.pagesSettings}
          isIntroduction={true}
          targetPosition={controls.current?.getCurrentTarget() ?? new Vector3()}
        />
      }
      <ReactUI
        setOrbitConfig={setOrbitConfig}
        orbitConfigTemplate={orbitConfigTemplate}
        isPresentation={true}
        setIsIntroduction={setIsIntroduction}
        controls={controls}
        refScene={refScene}
        setActiveObjectIds={setActiveObjectIds}
        resetActiveColorModel={resetActiveColorModel}
        activeObjectIds={activeObjectIds.current}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { scene } = state;
  return {
    settings: scene.settings,
    meshes: scene.meshes,
    hotspots: scene.hotspots,
    fbxs: scene.fbxs,
    isLoading: scene.isLoading,
    pagesSettings: scene.pagesSettings,
  };
};
export default connect(mapStateToProps)(Presentation);
