import LeftPanel from "./left-panel";
import { useEffect, useState } from "react";
import UpsertPlayList from "./upsertPlaylist";
import { useNavigate } from "react-router-dom";
import { SpinLoading } from "../../../components/svgs/icons";

export const CMSPlaylist = ({ accessMiddleware }) => {
  const navigate = useNavigate();
  
  const [playlistState, setPlaylistState] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    accessMiddleware(navigate, "/403cms", () => setLoading(false));
  }, []);

  if (loading) return (
    <div className="w-full h-screen flex justify-center items-center" role="tabpanel">
      <SpinLoading className="!size-12" />
    </div>
  );

  return (
    <section className="flex h-[calc(100vh-80px)] bg-[rgb(244,244,244)] p-4 gap-4">
      <LeftPanel
        playlistState={playlistState}
        setPlaylistState={setPlaylistState}
      />
      <div className="relative bg-white rounded-lg right-content flex-1 pt-5 overflow-hidden">
        <UpsertPlayList playlistId={playlistState?.path} />
      </div>
    </section>
  );
};
