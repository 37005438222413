import FAQTabs from "./components/faq-tabs";
import CreateIcon from "../components/Create";
import CmsPageHeader from "../components/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import RequestSupportModal from "./components/zendesk-modal";
import { reqGetListFAQs } from "../../../reduxs/cms/action";
import { reqGetUserProfile } from "../../../reduxs/user/action";
import { CollapsibleContent } from "../../../components/collapsable-content";
import Loading from "../configuration/components/loading";

const tabs = [
  { type: "app", title: "APP", eventKey: "content-app" },
  {
    type: "hardware",
    title: "SALES GALLERY HARDWARE",
    eventKey: "content-hardware",
  },
  { type: "tv_display", title: "TV DISPLAY", eventKey: "tv-display" },
];

const CmsFAQs = () => {
  const dispatch = useDispatch();
  const faqList = useSelector((state) => state.cms.faqs);
  const authUser = useSelector((state) => state.user.data);
  const [activeTab, setActiveTab] = useState("app");

  const [isShowForm, setIsShowForm] = useState(false);

  useEffect(() => {
    dispatch(reqGetListFAQs());
    dispatch(reqGetUserProfile());
  }, []);

  const filteredFaqs = useMemo(() => {
    return faqList?.filter((item) => item.type === activeTab) || [];
  }, [activeTab, faqList]);

  return (
    <div className="page-container h-[calc(100dvh_-_80px)]">
      <CmsPageHeader title={"FAQS"} />
      <FAQTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="ml-[108px] flex flex-col">
        <div className="scroll-view mr-[60px] mt-[100px] !h-[calc(100dvh_-_361px_-_130px)]">
          {filteredFaqs?.length ? (
            filteredFaqs?.map((item) => {
              return (
                <CollapsibleContent
                  key={item?.id}
                  title={item?.question}
                  content={item?.answer}
                />
              );
            })
          ) : (
            <div className="flex justify-center">
              <Loading width="w-5" height="h-5" />
            </div>
          )}
        </div>
        <CreateIcon onClick={() => setIsShowForm(true)} />
      </div>
      <RequestSupportModal
        show={isShowForm}
        setShow={setIsShowForm}
        user={authUser}
      />
    </div>
  );
};
export default CmsFAQs;
