import { toast } from "react-toastify";
import CreateIcon from "../components/Create";
import usersAPI from "../../../apis/api/user";
import { useNavigate } from "react-router-dom";
import { AddStaffModal } from "./AddStaffModal";
import { EditStaffModal } from "./EditStaffModal";
import { useDispatch, useSelector } from "react-redux";
import { SpinLoading } from "../../../components/svgs/icons";
import React, { useState, useEffect, useCallback } from "react";
import { reqGetUserProfile } from "../../../reduxs/user/action";
import { reqGetListStaff, reqGetListUserGroups } from "../../../reduxs/cms/action";

const Row = ({ data, onEditRow, handleUpdateActive, head = false }) => {
  const [show, setShow] = useState(false);

  if (head) {
    return (
      <div className="grid grid-cols-[18%_25%_32%_15%_calc(10%_-_35px)_35px] pb-6 pr-2">
        <div className="text-black font-poppins text-sm font-bold leading-normal uppercase inline-block">
          NAME
        </div>
        <div className="text-black font-poppins text-sm font-bold leading-normal uppercase inline-block">
          ROLES
        </div>
        <div className="text-black font-poppins text-sm font-bold leading-normal uppercase inline-block">
          EMAIL ADDRESS
        </div>
        <div className="text-black font-poppins text-sm font-bold leading-normal uppercase inline-block">
          EXTRA TYPE
        </div>
        <div className="text-black font-poppins text-sm font-bold leading-normal uppercase inline-block">
          ACTIVE
        </div>
        <div className="text-black font-poppins text-sm font-bold leading-normal uppercase inline-block"></div>
      </div>
    )
  }

  return (
    <div className="py-1 grid grid-cols-[18%_25%_32%_15%_calc(10%_-_35px)_35px] min-h-[35px] border-b-[1px] border-solid border-[#C5C5C566]">
      <div className="pr-2 text-black font-poppins text-sm leading-normal items-center inline-flex">
        {data?.firstname} {data?.surname}
      </div>
      <div className="pr-2 text-black font-poppins text-sm leading-normal items-center inline-flex">
        {(data?.userGroups || [])?.map(uG => uG?.name)?.join(', ')}
      </div>
      <div className="pr-2 text-black font-poppins text-sm leading-normal items-center inline-flex">
        {data?.email}
      </div>
      <div className="pr-2 text-black font-poppins text-sm leading-normal items-center inline-flex">
        {data.type ? _.startCase(_.camelCase(data.type)) : ''}
      </div>
      <div className="flex items-center">
        <div className="flex items-center h-full">
          <input
            className={`m-0 p-0 bg-[#CCCCCC59] w-[44px] h-[20px] !switch-bg-image !border-none bg-black rounded-[50px] checked:bg-[right] !bg-no-repeat !shadow-none cursor-pointer !checked:bg-[#000000] !checked:bg-auto !bg-auto transition-[300ms_ease-in-out]`}
            type="checkbox"
            id="active-state-1"
            defaultChecked={data?.isActive}
            onChange={(e) => handleUpdateActive(e, data.id)}
          />
          <label className="form-check-label" htmlFor="active-state-1" />
        </div>
      </div>
      <div onClick={() => onEditRow?.(data)} className="flex items-center">
        <div className="text-right rounded-[50px] bg-[#D3D0D159] w-[31px] h-[10px] cursor-pointer flex justify-center items-center">
          <img className="w-full h-full" src="/icons/edit-button.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

const CMSStaffs = ({ accessMiddleware }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isShowEditMe, setIsShowEditMe] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState("");
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [loading, setLoading] = useState(true);

  const authUser = useSelector((state) => state.user.data);
  const staff = useSelector((state) => state.cms.staff);

  // useEffect(() => {
  //   getDataList();
  // }, [search, isSortAsc]);

  useEffect(() => {
    accessMiddleware(navigate, "/403cms", () => setLoading(false));
    getDataList();
    dispatch(reqGetListUserGroups());
  }, []);

  const getDataList = useCallback(() => {
    dispatch(
      reqGetListStaff({
        search,
        sortBy: JSON.stringify({
          name: isSortAsc ? 1 : -1,
        }),
      })
    );
  }, [search, isSortAsc]);

  const onSaveSuccess = () => {
    getDataList();
    dispatch(reqGetUserProfile());
  };

  const onCreateSuccess = () => {
    getDataList();
  };

  const onEditRow = (data) => {
    setSelected(data);
    setIsShowEditModal(true);
  };

  const handleUpdateActive = async (e, id) => {
    if (!id) return;

    try {
      const updated = {
        isActive: e?.target?.checked || false,
      };
      const res = await usersAPI.updateUser(id, updated);
      if (res.data) {
        toast.success("User updated!");
        getDataList();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  if (loading) return (
    <div className="w-full h-screen flex justify-center items-center" role="tabpanel">
      <SpinLoading className="!size-12" />
    </div>
  );

  return (
    <div className="page-container">
      <div className="page-body m-0 pt-[64px] px-[108px] pb-0 flex flex-col items-center overflow-auto h-[calc(100vh-131px-80px]">
        <div className="flex flex-col gap-5 h-20 text-black font-poppins text-base min-w-[1150px] w-full overflow-auto pr-2">
          <div className="uppercase">
            Logged in as: <b>{authUser?.firstname} {authUser?.surname}</b>
          </div>
          <div className="flex justify-between text-sm">
            <div className="flex gap-10">
              <div>Group: <b>{(authUser?.userGroups || [])?.map(uG => uG?.name)?.join(', ')}</b></div>
              {authUser?.email && <div>Email: <b>{authUser?.email}</b></div>}
              {authUser?.phone && <div>Phone: <b>{authUser?.phone}</b></div>}
            </div>
            <div className="flex gap-12">
              <div className="flex gap-3">
                Active:
                <div className="flex items-center h-full">
                  <input
                    className={`m-0 p-0 bg-[#CCCCCC59] w-[44px] h-[20px] !switch-bg-image !border-none bg-black rounded-[50px] checked:bg-[right] !bg-no-repeat !shadow-none cursor-pointer !checked:bg-[#000000] !checked:bg-auto !bg-auto transition-[300ms_ease-in-out] disabled:opacity-50`}
                    type="checkbox"
                    id="active-state-1"
                    defaultChecked={authUser?.isActive}
                    disabled
                  />
                  <label className="form-check-label" htmlFor="active-state-1" />
                </div>
              </div>
              <div className="flex items-center" onClick={() => setIsShowEditMe(true)}>
                <div className="text-right rounded-[50px] bg-[#D3D0D159] w-[31px] h-[10px] cursor-pointer flex justify-center items-center">
                  <img className="w-full h-full" src="/icons/edit-button.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="h-[1px] w-full bg-black mt-4" />
        <div className="text-[#1A1A1A] text-[24px] min-w-[1150px] w-full h-full overflow-auto mt-10">
          <Row head />
          <div className="h-[calc(100vh-75px-80px-200px-80px)] overflow-auto pr-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            {(staff || []).map((item, index) => (
              <Row
                key={item.id}
                data={item}
                index={index}
                onEditRow={onEditRow}
                handleUpdateActive={handleUpdateActive}
              />
            ))}
          </div>
        </div>
        <div className="w-full" onClick={() => setIsShowAddModal(true)}>
          <CreateIcon createStaff />
        </div>
      </div>
      {isShowEditMe && (
        <EditStaffModal
          staff={authUser}
          show={isShowEditMe}
          setShow={setIsShowEditMe}
          onSaveSuccess={onSaveSuccess}
          disableRole
        />
      )}
      {isShowEditModal && (
        <EditStaffModal
          staff={selected}
          show={isShowEditModal}
          setShow={setIsShowEditModal}
          onSaveSuccess={onSaveSuccess}
        />
      )}
      {isShowAddModal && (
        <AddStaffModal
          show={isShowAddModal}
          setShow={setIsShowAddModal}
          onCreateSuccess={onCreateSuccess}
        />
      )}
    </div>
  );
};
export default CMSStaffs;
