import React, { Fragment, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";

import {
  Button,
  CreateMultiSelectField,
  MultiSelectField,
} from "../../../components/commons";
import mediaApi from "../../../../../apis/api/media";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { mergeUnitMetadata } from "../../utils";

const BulkEditorForm = ({
  setIsShow,
  mediaSelectedList,
  onSuccess,
  mediasRes,
}) => {
  const bulkOptions = useSelector((state) => state.cms.bulkOptions);

  const defaultValues = useMemo(() => {
    const arrays = mediaSelectedList.map((item) =>
      _.find(mediasRes, { id: item })
    );

    return mergeUnitMetadata(arrays);
  }, [mediasRes, mediaSelectedList]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape(
        Object.fromEntries(
          bulkOptions
            .filter((item) => item.visible && item.allowAdd)
            .map((item) => [
              item.key,
              yup
                .mixed()
                .optional()
                .test(
                  "is-number",
                  `${item.label} must be a number, greater than 0 and integer`,
                  (value) => {
                    if (value === undefined || value?.length === 0) return true;

                    return (
                      value?.length > 0 &&
                      value.every((item) => {
                        const processedValue = item.value
                          ? Number(item.value.replace(/"/g, ""))
                          : Number(item);

                        return (
                          !isNaN(processedValue) &&
                          processedValue > 0 &&
                          Number.isInteger(processedValue)
                        );
                      })
                    );
                  }
                ),
            ])
        )
      ),
    [bulkOptions]
  );

  const { control, formState, handleSubmit, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    const convertValue = (val) => {
      if (val === "true") return true;
      if (val === "false") return false;
      if (!isNaN(Number(val))) return Number(val);
      return val;
    };

    const visibleKeys = bulkOptions
      .filter((option) => option.visible !== false)
      .map((option) => option.key);

    const filters = _.mapValues(_.pick(data, visibleKeys), (value) => {
      if (_.isArray(value)) {
        const extractedValues = value
          .map((item) => {
            if (_.isObject(item) && item.value) {
              return convertValue(item.value);
            }
            return null;
          })
          .filter((v) => !_.isNull(v));
        return extractedValues.length ? extractedValues : [];
      }
      if (_.isUndefined(value)) {
        return [];
      }
      if (_.isString(value)) {
        return convertValue(value);
      }
      return value;
    });

    const res = await mediaApi.updateBulkUnitMedia({
      medias:
        mediaSelectedList && mediaSelectedList?.length
          ? mediaSelectedList
          : ["6757f57cd9da0e00394321ad"], //TODO:
      filters,
    });
    if (res?.data) {
      toast.success("Update successfully");
      onSuccess && onSuccess();
    } else {
      toast.error("Update failed");
    }
  };

  return (
    <div>
      <h2 className="mb-2 font-bold text-xl">
        {(mediaSelectedList && mediaSelectedList?.length) || 0} Assets Selected
      </h2>
      <div>
        {bulkOptions?.map((item) => {
          if (!item?.visible) return null;
          if (
            item?.label === "Total Size" ||
            item?.label === "Interior" ||
            item?.label === "Price Range" ||
            item?.label === "Availability"
          )
            return null; //TODO: we can show if new logic

          return (
            <Fragment key={item?.key}>
              {item?.allowAdd ? (
                <div className="mb-4 flex justify-between items-center">
                  <div className="w-1/4">
                    <p>{item?.label} :</p>
                    <p className="text-xs text-green-600">( Create new )</p>
                  </div>
                  <div className="flex-1">
                    <CreateMultiSelectField
                      controller={{
                        control,
                        name: item?.key,
                      }}
                      placeholder="Select from list or type in your input"
                      selectProps={{
                        isMulti: true,
                      }}
                      options={item?.options?.map((item) => ({
                        value: String(item),
                        label:
                          typeof item === "boolean"
                            ? item
                              ? "yes"
                              : "no"
                            : String(item),
                      }))}
                    />
                  </div>
                </div>
              ) : (
                <div className="mb-4 flex justify-between items-center">
                  <span className="w-1/4">{item?.label} :</span>
                  <div className="flex-1">
                    <MultiSelectField
                      selectProps={{
                        isMulti: true,
                      }}
                      controller={{
                        control,
                        name: item?.key,
                      }}
                      placeholder="Select from list"
                      options={item?.options?.map((item) => ({
                        value: String(item),
                        label:
                          typeof item === "boolean"
                            ? item
                              ? "yes"
                              : "no"
                            : String(item),
                      }))}
                    />
                  </div>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
      <div className="flex gap-5 mt-10">
        <Button
          onClick={() => setIsShow(false)}
          className="flex-1"
          variant="text"
        >
          Cancel
        </Button>
        <Button
          className="flex-1"
          disabled={!formState.isDirty}
          isLoading={formState?.isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Bulk Update
        </Button>
      </div>
    </div>
  );
};

export default BulkEditorForm;
