/** @format */

import { toast } from "react-toastify";
import mediaAPI from "../../apis/api/media";
import _ from "lodash";
import { getS3BEMediaUrl } from "../../helper/media";
import { initialData } from "./virtual-tour";

export const MEDIA_TYPE = {
  IMAGE: "images",
  VIDEO: "videos",
  FLOORPLANS: "floorplans",
  VIRTUALTOURS: "virtualTours",
  PARONAMIC: "paronamics",
  MODELS: "models",
  VIEWLINES: "viewlines",
  "360IMAGE": "360image",
};

const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200MB
const UPLOAD_TIMEOUT = 10 * 60 * 1000; // 10 mins
export const PAGE_SIZE = 9;

export const VIDEO_TYPE = [".mp4", ".avi", ".mov", ".webm", ".quicktime"];
export const IMAGE_TYPE = [".png", ".webp"];

export const getUploadParams = async (files, mediaType, mediaTitle) => {
  if (files.length < 1) return;

  const acceptType = mediaType === MEDIA_TYPE.VIDEO ? VIDEO_TYPE : IMAGE_TYPE;
  const allAcceptedTypes = [...VIDEO_TYPE, ...IMAGE_TYPE];

  const isAcceptedTypes = files?.map((file) => {
    const fileType = file?.type?.split("/")[1];

    if (!fileType) return false;

    if (mediaType) {
      return acceptType.includes(`.${fileType}`);
    } else {
      return allAcceptedTypes.includes(`.${fileType}`);
    }
  });

  if (_.includes(isAcceptedTypes, false)) {
    toast.warn("Invalid file type selected");
    return [];
  }
  let results = [];

  try {
    for (let file of files) {
      if (file.size > MAX_FILE_SIZE) {
        toast.error(
          `File ${file.name} is too large. Maximum size is ${
            MAX_FILE_SIZE / 1024 / 1024
          }MB`
        );
        return [];
      }

      if (mediaType === MEDIA_TYPE.PARONAMIC) {
        const { width, height } = await getImageResolution(file);

        if (width / height < 3) {
          toast.error(
            `File ${file.name} is not a ${mediaTitle} asset. Please upload an image with aspect ratio at least 3:1.`
          );
          return [];
        }
      }

      if (mediaType === MEDIA_TYPE["360IMAGE"]) {
        const { width, height } = await getImageResolution(file);

        // 2:1 aspect ratio equirectangular
        if (width / height !== 2) {
          toast.error(
            `File ${file.name} is not a ${mediaTitle} asset. Please upload a 2:1 aspect ratio image.`
          );
          return [];
        }
      }

      const formData = new FormData();
      formData.append("file", file);

      const uploadPromise =
        mediaType === MEDIA_TYPE.VIDEO
          ? mediaAPI.uploadMediaVideo(formData)
          : mediaAPI.uploadMedia(file);
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Upload timeout")), UPLOAD_TIMEOUT)
      );

      try {
        const uploaded = await Promise.race([uploadPromise, timeoutPromise]);

        const type =
          file?.type?.split("/")[0] === "image"
            ? MEDIA_TYPE.IMAGE
            : MEDIA_TYPE.VIDEO;

        if (uploaded?.data) {
          results.push({
            isActive: true,
            path: uploaded.data.path,
            thumbnailPath:
              uploaded.data.thumbnailPath || "/1727663621731_IMG_2551.webp",
            type: mediaType || type,
            name: file.name.split(".").slice(0, -1).join("."),
            fileName: file.name,
          });
        } else {
          throw new Error(`Error uploading file: ${file.name}`);
        }
      } catch (error) {
        if (error.message === "Upload timeout") {
          toast.warn(`Upload timeout for file: ${file.name}`);
        } else {
          toast.error(`Error uploading file: ${file.name}. ${error.message}`);
        }
        return [];
      }
    }
  } catch (e) {
    toast.warn("An unexpected error occurred during upload");
    return [];
  }

  return results;
};

export const MODEL_MEDIA_TYPES = {
  MAIN: "main",
  HELPERS: "helpers",
};

export function getImageResolution(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = function () {
      // file is loaded
      const img = new Image();

      img.onload = function () {
        resolve({ width: img.width, height: img.height }); // image is loaded; sizes are available
      };

      img.src = fileReader.result; // is the data URL because called with readAsDataURL
    };

    fileReader.readAsDataURL(file);
  });
}

export function transformedScenes(medias) {
  const newMedias = medias?.filter(
    (item) => item.type === MEDIA_TYPE.VIRTUALTOURS
  );

  const transformedScenes = newMedias.map((media, index) => ({
    id: media?.data?.id || media?.id,
    name: `Scene ${index + 1}`,
    url: media?.data?.url || `${getS3BEMediaUrl(media.path)}`,
    levels: media?.data?.levels || [
      { tileSize: 256, size: 256, fallbackOnly: true },
      { tileSize: 512, size: 512 },
      { tileSize: 512, size: 1024 },
      { tileSize: 512, size: 2048 },
      { tileSize: 512, size: 4096 },
    ],
    faceSize: media?.data?.faceSize || 4096,
    initialViewParameters: media?.data?.initialViewParameters || {
      pitch: 0,
      yaw: 0,
      fov: Math.PI / 3,
    },
    linkHotspots: media?.data?.linkHotspots || [],
    infoHotspots: media?.data?.infoHotspots || [],
  }));

  return {
    settings: {
      ...newMedias[0]?.data?.settings,
      autorotateEnabled: newMedias?.some(
        (item) => item?.data?.settings?.autorotateEnabled
      ),
    },
    scenes: transformedScenes,
  };
}

export const getStaffType = (userGroupName) => {
  switch (userGroupName) {
    case 'SA':
      return 'Super Admin'
    default:
      return userGroupName;
  }
}

export const generatePassword = (length = 12) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*';
  return Array.from(crypto.getRandomValues(new Uint8Array(length)), x => chars[x % chars.length]).join('');
};


export const joinAccessFromGroups = (groups) => {
  return (
    groups?.reduce((acc, group) => {
      const access = group?.rules?.map(r => r.name) || [];
      return group?.rules?.length ? acc.concat(...access) : acc;
    }, []) || []
  );
};

export const doesUserGroupsHaveRoute = (groups, routeKey) => {
  return (
    joinAccessFromGroups(groups).includes(routeKey) ||
    joinAccessFromGroups(groups).includes('*')
  );
}
