/**
 * /* eslint-disable no-unreachable
 *
 * @format
 */

import PATH from "./path";
import authApi from "../apis/api/auth";
import React, { useEffect } from "react";
import { routeOptions } from "./options";
import jwtAuth from "../apis/utils/jwtAuth";
import { getS3BEMediaUrl } from "../helper/media";
import { reqGetMeshes } from "../reduxs/scene/action";
import { useDispatch, useSelector } from "react-redux";
import { reqGetModalList } from "../reduxs/home/action";
import { reqGetUserProfile } from "../reduxs/user/action";
import { reqGetActiveConfig } from "../reduxs/cms/action";
import { doesUserGroupsHaveRoute } from "../pages/cms/utils";
import DynamicStaticPage from "../pages/cms/dynamic-static-page";
import { reqGetPageList } from "../reduxs/page-configuration/action";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { StaticPagesList } from "../pages/cms/dynamic-static-page/static-dashboard";

const redirectPath = "/holding";

export const Router = () => {
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.user.data);
  const pages = useSelector((state) => state.configuration.pages);
  const staticPage = pages?.find((page) => page?.name === "static_page");

  const activeConfig = useSelector((state) => state.cms.activeConfig);

  const loadConfig = async () => {
    dispatch(reqGetActiveConfig());
  };

  useEffect(() => {
    const url = getS3BEMediaUrl(
      activeConfig?.theme?.cssVariables?.find(
        (variable) => variable.name === "logo-favicon"
      )?.value
    );
    if (url) {
      const link =
        document.querySelector("link[rel='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "icon";
      link.href = url;
      document.head.appendChild(link);
    }
  }, [activeConfig]);

  const handleUpdateVariable = (values) => {
    values.map((i) => {
      document.documentElement.style.setProperty(`--${i?.name}`, i?.value);
    });
  };

  useEffect(() => {
    if (
      activeConfig &&
      activeConfig?.theme &&
      activeConfig?.theme?.cssVariables
    ) {
      handleUpdateVariable(activeConfig?.theme?.cssVariables || []);
    }
  }, [activeConfig]);

  useEffect(() => {
    if (authUser) {
      dispatch(reqGetMeshes());
      dispatch(reqGetPageList());
      dispatch(reqGetModalList());
    }
  }, [authUser]);

  useEffect(() => {
    dispatch(reqGetUserProfile());
    loadConfig();
  }, []);

  const authMiddleware = async (navigate, redirect = redirectPath) => {
    const isToken = await jwtAuth.checkToken();
    if (!isToken) {
      return navigate(redirect);
    }
  };

  const accessMiddleware =
    (accessKey) =>
    async (navigate, redirect = redirectPath, okCallback = () => {}) => {
      const isToken = await jwtAuth.checkToken();
      if (!isToken) {
        return navigate(redirect);
      } else {
        const userData = await getUserProfile(navigate, redirect);
        if (
          accessKey &&
          !doesUserGroupsHaveRoute((userData?.userGroups || []), accessKey)
        ) {
          return navigate(redirect);
        }
        okCallback();
      }
    };

  const getUserProfile = async (navigate, redirect = redirectPath) => {
    try {
      const res = await authApi.getMe();
      return res?.data;
    } catch (error) {
      return navigate(redirect);
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        {routeOptions.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              exact={item.exact}
              element={
                !item.subRoutes?.length ? (
                  <item.component authMiddleware={authMiddleware} />
                ) : undefined
              }
            >
              {item.subRoutes?.map((subRoute, _i) => {
                return (
                  <Route
                    key={_i}
                    path={subRoute.path}
                    element={
                      <item.component authMiddleware={authMiddleware}>
                        {subRoute.element ? (
                          <subRoute.element
                            accessMiddleware={accessMiddleware(subRoute.key)}
                          />
                        ) : (
                          <Outlet />
                        )}
                      </item.component>
                    }
                  >
                    {item.path === PATH.CMS_PAGE &&
                      subRoute.path === "static" && (
                        <Route
                          index
                          element={
                            <StaticPagesList
                              accessMiddleware={accessMiddleware(subRoute.key)}
                            />
                          }
                        />
                      )}
                    {item.path === PATH.CMS_PAGE &&
                      subRoute.path === "static" &&
                      staticPage?.modules?.length &&
                      staticPage?.modules?.map((module, _i) => {
                        return (
                          <Route
                            key={_i}
                            path={`${module?.props?.path}/:id`}
                            element={
                              module?.props?.visible ? (
                                <DynamicStaticPage
                                  accessMiddleware={accessMiddleware(
                                    subRoute.key
                                  )}
                                />
                              ) : (
                                <Navigate to={"/404"} replace />
                              )
                            }
                          />
                        );
                      })}
                  </Route>
                );
              })}
            </Route>
          );
        })}
        <Route
          path="*"
          element={() => <Navigate to={PATH.NOTFOUND_PAGE} replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
