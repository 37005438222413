/** @format */

import React, { useState, useEffect, useRef } from "react";
import RolesAccessDetail from "./components/roles-access-detail";
import { cn } from "../../../helper/utils";
import rolesAccess from "../../../apis/api/roles-access";

const CMSRolesAccess = () => {
  const [itemActive, setItemActive] = useState();
  const [listUserGroup, setListUserGroup] = useState([]);
  const refListAccessRules = useRef();
  const [isCreate, setCreate] = useState(false);

  useEffect(() => {
    getListUserGroup();
    getListAccessRules();
  }, []);
  const getListAccessRules = () => {
    try {
      rolesAccess.getListAccessRules().then((res) => {
        if (res && res?.data) {
          refListAccessRules.current = res.data;
        }
      });
    } catch (e) {
      console.log("Error getListAccessRules", e);
    }
  };
  const getListUserGroup = (isRenew = false) => {
    try {
      if (isRenew) {
        setItemActive(null);
        setCreate(false);
      }
      rolesAccess.getListUserGroup().then((res) => {
        if (res && res?.data) {
          setListUserGroup(res.data);
        }
      });
    } catch (e) {
      console.log("Error get list user group", e);
    }
  };
  const onClickItem = (val) => {
    setCreate(false);
    setItemActive(val);
  };

  return (
    <div className="flex bg-[rgb(244,244,244)]">
      <section className="bg-white p-4 border-r-[1px] border-gray-300 h-[calc(100vh-80px)] w-[350px]">
        <div className="flex justify-between items-center">
          <h2 className="font-bold">Roles & Access</h2>
          <button
            onClick={() => setCreate(true)}
            className={`flex items-center p-2 rounded text-black hover:!text-white hover:!bg-black`}
          >
            <div className="border-b border-b-black text-sm font-medium font-sans tracking-normal leading-none">
              Add
            </div>
          </button>
        </div>
        <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
        <div className="my-2 h-[calc(100vh-150px)] overflow-y-auto space-y-2">
          {listUserGroup.length > 0 &&
            listUserGroup.map((i) => {
              return (
                <button
                  key={i.id}
                  className={cn(
                    "w-full text-black flex-wrap flex justify-between items-center p-3 text-sm font-medium text-left bg-purple-200 hover:bg-purple-300 rounded",
                    {
                      "bg-green-600 hover:bg-green-600 text-white font-semibold":
                        i.id === itemActive?.id,
                    }
                  )}
                  onClick={() => onClickItem(i)}
                >
                  <span className="flex-1 text-left">{i.name}</span>
                </button>
              );
            })}
        </div>
      </section>
      {(itemActive || isCreate) && (
        <RolesAccessDetail
          isCreate={isCreate}
          idSelected={itemActive?.id}
          rules={refListAccessRules.current}
          refreshUserGroup={getListUserGroup}
        />
      )}
    </div>
  );
};

export default CMSRolesAccess;
