/** @format */

import React, { useEffect } from "react";
import socket from "../../helper/socket";
import { useDispatch, useSelector } from "react-redux";
import { MODULES_ENUM, PAGES_ENUM } from "../../constants/modules";
import { reqGetAmenitiesList } from "../../reduxs/amenities/action";
import { ReactComponent as Arrow } from "../../assets/images/arrow.svg";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const Amenities = ({ handleClickAmenity, isPresentation, resetState }) => {
  const dispatch = useDispatch();

  const amenities = useSelector((state) => state.amenities.data);
  const activeAmenity = useSelector((state) => state.amenities.activeAmenity);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_AMENITY_ITEM_PANEL) {
          return handleAmenityItemClick(content.data.item);
        }
        if (content.action === ACTION_NAME.CLEAR_AMENITY_ITEM_PANEL) {
          return clearSelection();
        }
      });
    }
  }, [isPresentation]);

  useEffect(() => {
    clearSelection();
    dispatch(reqGetAmenitiesList());
  }, []);

  const handleAmenityItemClick = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_AMENITY_ITEM_PANEL, {
        item,
      });
    }
    handleClickAmenity(item);
  };

  const clearSelection = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLEAR_AMENITY_ITEM_PANEL);
    }
    handleClickAmenity(null);
    resetState();
  };

  return (
    <div className="w-[225px] rounded-r-md px-[25px] py-[30px] bg-brand h-[calc(100vh_-_200px)] absolute top-[100px] flex flex-col">
      <div className="p-0 mb-[30px]">
        <h2 className="font-domaine tracking-[0.07rem] text-primary text-2xl font-normal leading-none uppercase">
          {"AMENITIES"}
        </h2>
      </div>
      <div className="flex flex-col gap-3 py-3 border-t-[1px] border-t-brand-secondary">
        {amenities.map((amenity) => {
          return (
            <div
              key={amenity.id}
              onClick={() => handleAmenityItemClick(amenity)}
              className="flex items-center cursor-pointer text-primary"
            >
              <div
                className={`${
                  activeAmenity?.id === amenity?.id ? "underline" : ""
                } leading-normal`}
              >
                {amenity?.name}
              </div>
              {activeAmenity?.id === amenity?.id && (
                <Arrow className="h-fit rotate-180 ml-auto !fill-[var(--primary-color)]" />
              )}
            </div>
          );
        })}
      </div>
      <div
        className="txt-bottom flex h-6 mt-auto cursor-pointer justify-center items-center"
        onClick={clearSelection}
      >
        <span className="text-primary font-sans text-sm leading-normal tracking-[1.92px] uppercase">
          {"CLEAR SELECTION"}
        </span>
      </div>
    </div>
  );
};

Amenities.displayName = MODULES_ENUM.AMENITY_EXPLORE;
Amenities.pageGroup = PAGES_ENUM.INTERACTIVE_3D;

export default Amenities;
