/** @format */

import React, { memo } from "react";
import { useSelector } from "react-redux";
import StaticPageType2 from "../../components/ppg";
import StaticPageType1 from "../../components/neighborhood";
import Session from "../../components/session";
import Location from "../../components/location";
import Amenities from "../../components/amenities";
import { REACTUI_PAGES } from "../../constants/options";
import UnitExplore from "../../components/unit-explore";
import GalleryLanding from "../../components/gallery-landing";
import TransportOption from "../../components/transport-option";
import GalleryLandingA from "../../components/gallery-landing-type-a";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import EndGuideTenantSessionModal from "../../components/guide-sesions/end-guide-tenant-session-modal";

const ReactuiPages = (props) => {
  const {
    resetState,
    isPresentation,
    handleUnitClick,
    setActiveObjectIds,
    handleClickAmenity,
    handleClickTransportOptions,
  } = props;

  const pages = useSelector((state) => state.configuration.pages);
  const reactUiPage = useSelector((state) => state.home.reactUiPage);

  // configure relationships between components and pages
  const pageComponentsMatrix = [
    {
      item: UnitExplore,
      reactUiPage: REACTUI_PAGES.UNIT_EXPLORER_PAGE,
      transitionTimeout: 1000,
      props: {
        isPresentation,
        handleUnitClick,
        setActiveObjectIds,
      },
    },
    {
      item: Amenities,
      reactUiPage: REACTUI_PAGES.AMENITIES_PAGE,
      transitionTimeout: 200,
      props: {
        handleClickAmenity,
        isPresentation,
        resetState,
      },
    },
    {
      item: GalleryLandingA,
      reactUiPage: REACTUI_PAGES.GALLERY_PAGE_TYPE_A,
      transitionTimeout: 200,
      props: {
        isPresentation,
      },
    },
    {
      item: GalleryLanding,
      reactUiPage: REACTUI_PAGES.GALLERY_PAGE_TYPE_A,
      transitionTimeout: 200,
      props: {
        isPresentation,
      },
    },
    {
      item: TransportOption,
      reactUiPage: REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE,
      transitionTimeout: 200,
      props: {
        handleClickTransportOptions,
      },
    },
    {
      item: Location,
      reactUiPage: REACTUI_PAGES.LOCATION_PAGE,
      transitionTimeout: 1000,
      props: {
        isPresentation,
      },
    },
  ];

  const renderModules = () => {
    return pageComponentsMatrix.map((pageComp, _i) => {
      const pageConfig = pages?.find(
        (page) => page.name === pageComp.item.pageGroup
      );
      const pageModuleNames = pageConfig?.modules?.map((m) => m.name) || [];
      if (
        pageConfig &&
        pageConfig?.props?.visible &&
        pageModuleNames.includes(pageComp.item.displayName)
      ) {
        const Module = pageComp.item;
        const props = pageComp.props;
        const moduleConfig = pageConfig.modules.find(
          (m) => m.name === pageComp.item.displayName
        );
        return (
          <TransitionGroup key={_i}>
            {reactUiPage === pageComp.reactUiPage && (
              <CSSTransition
                timeout={1000}
                classNames="fade-left"
                unmountOnExit
              >
                <Module
                  {...props}
                  {...(moduleConfig?.props || {})}
                  moduleConfig={moduleConfig}
                />
              </CSSTransition>
            )}
          </TransitionGroup>
        );
      }
      return null;
    });
  };

  return (
    <>
      {renderModules()}
      <TransitionGroup>
        {reactUiPage == REACTUI_PAGES.GALLERY_PAGE_TYPE_B && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <GalleryLanding pages={pages} isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {reactUiPage == REACTUI_PAGES.GALLERY_PAGE_TYPE_A && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <GalleryLandingA pages={pages} isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {reactUiPage == REACTUI_PAGES.PPG_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <StaticPageType2 isPresentation={isPresentation} pages={pages} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {reactUiPage == REACTUI_PAGES.NEIGHBORHOOD_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <StaticPageType1 isPresentation={isPresentation} pages={pages} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {reactUiPage == REACTUI_PAGES.SESSION_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Session isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {reactUiPage == REACTUI_PAGES.END_GUIDE_TENANT_SESSION_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <EndGuideTenantSessionModal resetState={resetState} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default memo(ReactuiPages);
