import React, { useEffect, useState } from 'react';
import FormInput from './form-input';
import authApi from '../../apis/api/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { validatePassword } from '../../helper/validation';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const FormResetPassword = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState({});
  const [dirty, setDirty] = useState({});

  useEffect(() => {
    if (!dirty.password) return;
    validatePasswordFunc()
  }, [password, dirty.password]);

  useEffect(() => {
    if (!dirty.passwordConfirmation) return;
    validatePasswordConfirmFunc()
  }, [passwordConfirmation, dirty.passwordConfirmation]);

  const validatePasswordFunc = () => {
    if (validatePassword(password) && dirty.password) {
      delete message.password;
      setMessage({ ...message });
      return true;
    } else {
      message.password = "Invalid password"
      setMessage({ ...message });
      return false;
    }
  }

  const validatePasswordConfirmFunc = () => {
    if (password === passwordConfirmation && dirty.passwordConfirmation) {
      delete message.passwordConfirmation;
      setMessage({ ...message });
      return true;
    } else {
      message.passwordConfirmation = 'Password confirmation not match';
      setMessage({ ...message });
      return false;
    }
  }

  const handleResetPassword = async () => {
    if (!validatePasswordFunc() || !validatePasswordConfirmFunc()) return;
    try {
      const res = await authApi.resetPassword({
        password: password,
        password_confirmation: passwordConfirmation,
        token: query.get('token'),
      });
      if (res.data) {
        toast.success("Password reset successfully");
        navigate('/holding');
      } else {
        toast.error("Link is expired")
      }
    } catch (error) {
    }
  };

  return (
    <div className="flex flex-col justify-center w-[534px]">
      <FormInput
        type="password"
        onChange={(e) => {
          setDirty({ ...dirty, password: true });
          setPassword(e.target.value);
        }}
        id="email"
        placeholder="Password"
      />
      {
        message.password &&
        <p className="text-red-500 mt-2">
          {message.password}
        </p>
      }
      <FormInput
        type="password"
        onChange={(e) => {
          setDirty({ ...dirty, passwordConfirmation: true });
          setPasswordConfirmation(e.target.value);
        }}
        className='mt-6'
        id="email"
        placeholder="Password Confirmation"
      />
      {
        message.passwordConfirmation &&
        <p className="text-red-500 mt-2">
          {message.passwordConfirmation}
        </p>
      }
      <div className="bg-button-bottom">
        <button
          type="submit"
          onClick={handleResetPassword}
          className="flex justify-center items-center border-none bg-black w-full h-[60px] mt-[25px] mb-6"
        >
          <span className="text-white text-center font-sans text-sm font-normal leading-normal uppercase tracking-[2.25px]">
            ENTER
          </span>
        </button>
      </div>
    </div>
  );
};

export default FormResetPassword;
