/** @format */

import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { reqGetPageList } from "../../../reduxs/page-configuration/action";
import { cn } from "../../../helper/utils";
import GalleryTypeA from "./typeA";
import GalleryTypeB from "./typeB";
import { getValuesAfterUnderscore } from "./utils";
import { Button } from "../components/commons";
import RightArrowIcon from "../../../components/svgs/RightArrowIcon";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { SpinLoading } from "../../../components/svgs/icons";

export const CMSGallery = ({ accessMiddleware }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pages: pagesRes, loading: loadingPages } = useSelector(
    (state) => state.configuration
  );
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    accessMiddleware(navigate, "/403cms", () => setLoading(false));
    dispatch(reqGetPageList({}));
  }, []);

  let galleryTypeA = useMemo(() => {
    const item = pagesRes?.find((v) => v?.name === "gallery_page_type_a");
    const data = {
      ...item,
      visiblePage: item?.props.visible,
      data: {
        ...item?.modules[0],
      },
      tabs: item?.modules[0]?.features[0]?.components?.map((v) => ({
        galleryId: v?.id,
        label: getValuesAfterUnderscore(v?.name),
        name: _.camelCase(v?.name),
        visible: v?.props?.visible,
        type: v?.props?.type,
      })),
    };

    return data;
  }, [pagesRes]);

  const galleryTypeB = useMemo(() => {
    const item = pagesRes?.find((v) => v?.name === "gallery_page_type_b");
    const newModules = item?.modules?.filter((item) => item?.props?.visible);

    const allTabs = _.flatMap(newModules, (module) => {
      return _.map(_.get(module, "features[0].components"), (component) => ({
        galleryId: component.id,
        nameProject: module.name,
        label: getValuesAfterUnderscore(component.name),
        name: _.camelCase(component?.name),
        visible: component.props?.visible,
        type: component.props?.type,
      }));
    });

    return {
      ...item,
      visiblePage: item?.props.visible,
      tabs: allTabs,
    };
  }, [pagesRes]);

  const defaultPage = useMemo(() => {
    if (!loadingPages) {
      const galleryAPage =
        galleryTypeA?.visiblePage &&
        galleryTypeA?.tabs?.find((tab) => tab.visible);

      if (galleryAPage && galleryTypeA?.data?.props?.visible) {
        return galleryAPage;
      }

      const galleryBPage =
        galleryTypeB?.visiblePage &&
        galleryTypeB?.tabs?.find((tab) => tab.visible);

      if (galleryBPage) {
        return galleryBPage;
      }
    }
    return null;
  }, [loadingPages, galleryTypeA, galleryTypeB]);

  useEffect(() => {
    defaultPage && setPage(defaultPage);
  }, [defaultPage]);

  const isShowGalleryTypeBLeftSide = galleryTypeB && galleryTypeB?.visiblePage;
  const isShowGalleryTypeALeftSide =
    galleryTypeA &&
    galleryTypeA?.visiblePage &&
    galleryTypeA?.data?.props?.visible;

  if (loading) return (
    <div className="w-full h-screen flex justify-center items-center" role="tabpanel">
      <SpinLoading className="!size-12" />
    </div>
  );

  return (
    <div>
      <div className="page-header bg-black flex justify-center items-center pt-4 pb-4 h-12">
        <h1 className="font-poppins font-bold text-[30px] leading-[45px] uppercase text-center text-white mb-0">
          GALLERY
        </h1>
      </div>
      {!loadingPages && (
        <div>
          {(isShowGalleryTypeALeftSide || isShowGalleryTypeBLeftSide) && (
            <div className="flex bg-[rgb(244,244,244)]">
              <div
                className={cn(
                  "w-full bg-white p-2 border-r-[1px] border-gray-300 h-[calc(100dvh-120px)] overflow-y-auto",
                  {
                    "w-[350px]": !!page,
                  }
                )}
              >
                {isShowGalleryTypeALeftSide && (
                  <div>
                    <p className="flex-1 ">Gallery A</p>
                    <div className="my-2 space-y-2">
                      {galleryTypeA?.tabs?.map((tab, i) => (
                        <button
                          key={`tab-${i}`}
                          className={cn(
                            "w-full text-black flex-wrap flex justify-between items-center p-3 text-sm font-medium text-left bg-purple-200 hover:bg-purple-300 rounded",
                            {
                              hidden: !tab?.visible,
                            },
                            {
                              "bg-green-600 hover:bg-green-600 text-white font-semibold":
                                page?.galleryId === tab?.galleryId,
                            }
                          )}
                          onClick={() => setPage(tab)}
                        >
                          <span className="flex-1 text-left">{tab?.name}</span>
                          <div className="flex gap-5 items-center">
                            <RightArrowIcon />
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                )}
                {isShowGalleryTypeBLeftSide && (
                  <div>
                    <ul
                      className={cn(
                        `flex w-full items-center gap-3 justify-between flex-1 py-2 mb-2 `
                      )}
                    >
                      <li className="flex-1">Gallery B</li>
                    </ul>
                    <div className="my-2 space-y-2">
                      {galleryTypeB?.tabs?.map((tab, i) => (
                        <button
                          key={`tab-${i}`}
                          className={cn(
                            "w-full text-black flex-wrap flex justify-between items-center p-3 text-sm font-medium text-left bg-purple-200 hover:bg-purple-300 rounded",
                            {
                              hidden: !tab?.visible,
                            },
                            {
                              "bg-green-600 hover:bg-green-600 text-white font-semibold":
                                page?.galleryId === tab?.galleryId,
                            }
                          )}
                          onClick={() => setPage(tab)}
                        >
                          <span className="flex-1 text-left">
                            {tab?.nameProject} / {tab?.name}
                          </span>
                          <div className="flex gap-5 items-center">
                            <RightArrowIcon />
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={cn("hidden", {
                  "flex-1 h-full p-4 block": !!page,
                })}
              >
                {galleryTypeA?.tabs?.some(
                  (v) => v?.galleryId === page?.galleryId
                ) && (
                  <GalleryTypeA
                    typeAEntity={isShowGalleryTypeALeftSide && page}
                  />
                )}
                {galleryTypeB?.tabs?.some(
                  (v) => v?.galleryId === page?.galleryId
                ) && <GalleryTypeB typeBEntity={page} />}
              </div>
            </div>
          )}
          {!isShowGalleryTypeALeftSide && !isShowGalleryTypeBLeftSide && (
            <div className="flex justify-center items-center h-[calc(100vh-80px)]">
              <p className="text-2xl font-medium">
                No gallery found. Please create one.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
