/** @format */
import { useDispatch, useSelector } from "react-redux";
import { cn } from "../../../helper/utils";
import { getS3BEMediaUrl } from "../../../helper/media";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { LazyLoadImage } from "react-lazy-load-image-component";
import React, { useRef, useEffect, useState } from "react";
import { reqGetListFolder } from "../../../reduxs/cms/action";
import mediaApi from "../../../apis/api/media";
import Search from "../gallery/Search";
import useDebouncedSearch from "../../../hooks/useDebouncedSearch";
import AddMeidaContentModal from "../media/add-modal";
import EditMediaContentModal from "../media/edit-modal";
import { getUploadParams, PAGE_SIZE } from "../utils";
import playlistApi from "../../../apis/api/playlist";
import LoadingTable from "../configuration/components/Table/LoadingTable";
import defaultVideo from "../../../assets/images/cms/icVideo.webp";
import defaultImage from "../../../assets/images/cms/icImg.webp";
import Pagination from "../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { SpinLoading } from "../../../components/svgs/icons";

const CMSAssets = ({ accessMiddleware }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const assets = useSelector((state) => state.cms.folders) || [];
  const [assetSeleted, setAssetSeleeted] = useState();
  const [medias, setMeida] = useState([]);
  const [search, setSearch] = useDebouncedSearch();
  const [isShowAddContentModal, setIsShowAddContentModal] = useState(false);
  const [isShowEditContentModal, setIsShowEditContentModal] = useState(false);
  const [loadingCreateMedia, setLoadingCreateMedia] = useState(false);
  const [seletedMedia, setSeletedMedia] = useState(null);
  const [totalItem, setTotalItem] = useState(0);
  const [loading, setLoading] = useState(true);

  const refSearch = useRef();
  const currentType = useRef();

  useEffect(() => {
    accessMiddleware(navigate, "/403cms", () => setLoading(false));
    getListFolder();
  }, []);

  useEffect(() => {
    if (assets && assets.length > 0) {
      setAssetSeleeted(assets[0]);
      loadDataMediaTab(assets[0].value);
    }
  }, [assets]);

  useEffect(() => {
    if (currentType.current) {
      loadDataMediaTab(currentType.current);
    }
  }, [search]);

  const getListFolder = async () => {
    dispatch(reqGetListFolder());
  };

  // const contentMedia = useMemo(() => {
  //   return !search
  //     ? medias
  //     : medias?.filter((media) =>
  //         media?.name?.toLowerCase()?.includes(search?.toLowerCase())
  //       ) || [];
  // }, [medias, search]);

  const loadDataMediaTab = async (mediaType, currentPage = 1) => {
    currentType.current = mediaType;
    const data = {
      "type[equal]": mediaType,
      meta: JSON.stringify({ page: currentPage, pageSize: PAGE_SIZE }),
      search: search,
    };
    const res = await mediaApi.getMediaList(data);
    if (res && res.data) {
      setTotalItem(res?.total || 0);
      setMeida(res.data);
    }
  };

  const getDataPagination = (nextPage) => {
    loadDataMediaTab(currentType.current, nextPage);
  };

  const handleAssets = (val) => {
    refSearch.current?.resetSearch();
    setAssetSeleeted(val);
    loadDataMediaTab(val.value);
  };

  const onCreateSuccess = () => {
    loadDataMediaTab(assetSeleted.value);
    setIsShowAddContentModal(false);
  };

  const onSaveSuccess = () => {
    loadDataMediaTab(assetSeleted.value);
    setIsShowEditContentModal(false);
  };

  const clickMedia = (val) => {
    setSeletedMedia(val);
    setIsShowEditContentModal(true);
  };

  const onDrop = async (files) => {
    const mediaType = assetSeleted?.value;

    if (!!files?.length) {
      setLoadingCreateMedia(true);
      const params = await getUploadParams(
        files,
        mediaType,
        assets.find((asset) => asset.value === mediaType).label
      );

      if (params?.length) {
        let completed = 0;

        await Promise.all(
          params.map(async (param) => {
            await playlistApi.createMediaPlaylist(param);
            completed++;
          })
        );
        toast.success("Add new content successfully!");
        await loadDataMediaTab(mediaType);
      }
      setLoadingCreateMedia(false);
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  if (loading) return (
    <div className="w-full h-screen flex justify-center items-center" role="tabpanel">
      <SpinLoading className="!size-12" />
    </div>
  );

  return (
    <div className="flex bg-[rgb(244,244,244)]">
      <section className="bg-white p-4 border-r-[1px] border-gray-300 h-[calc(100vh-80px)] w-[350px]">
        <h2 className="font-bold">Assets</h2>
        <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
        <div className="my-2 h-[calc(100vh-150px)] overflow-y-auto space-y-2">
          {assets.length > 0 &&
            assets.map((i) => {
              return (
                <button
                  key={i.id}
                  className={cn(
                    "w-full text-black flex-wrap flex justify-between items-center p-3 text-sm font-medium text-left bg-purple-200 hover:bg-purple-300 rounded",
                    {
                      "bg-green-600 hover:bg-green-600 text-white font-semibold":
                        i.id === assetSeleted?.id,
                    }
                  )}
                  onClick={() => handleAssets(i)}
                >
                  <span className="flex-1 text-left">{i.label}</span>
                </button>
              );
            })}
        </div>
      </section>
      {assetSeleted && (
        <section className="flex-1 h-full p-4 block">
          <div className="flex gap-2 items-center flex-row justify-between mb-1">
            <h2 className="capitalize font-semibold">{assetSeleted?.label}</h2>
            <div
              className="cursor-pointer"
              onClick={() => setIsShowAddContentModal(true)}
            >
              <p className="underline text-sm font-semibold">Add</p>
            </div>
          </div>
          <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
          <Search
            callbackOnInputChange={setSearch}
            formClassName="w-full"
            disabled={!medias?.length}
            ref={refSearch}
          />
          <div className="relative">
            <div
              className="h-[72vh] overflow-y-auto overflow-x-hidden"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive && (
                <div className="absolute inset-0 z-10 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-70">
                  <p className="text-2xl text-black font-bold">
                    Drop the files here ...
                  </p>
                </div>
              )}
              {loadingCreateMedia && (
                <div className="absolute inset-0 z-20 flex items-center justify-center bg-gray-200 bg-opacity-70">
                  <LoadingTable />
                </div>
              )}
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {medias.length > 0 &&
                  medias.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="rounded-[10px] cursor-pointer"
                        onClick={() => clickMedia(item)}
                      >
                        <LazyLoadImage
                          src={getS3BEMediaUrl(item?.thumbnailPath)}
                          className="h-[120px] w-full rounded-tl-lg rounded-tr-lg m-auto object-cover"
                          controls
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              item?.type === "videos"
                                ? defaultVideo
                                : defaultImage;
                            e.target.className =
                              "h-[120px] w-full rounded-tl-lg rounded-tr-lg m-auto object-contain";
                          }}
                        />

                        <div className="bg-black p-2 text-white text-xs">
                          {item?.name}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {totalItem !== 0 && (
              <Pagination
                total={totalItem}
                getDataPagination={getDataPagination}
                pageSize={PAGE_SIZE}
                resetPage={currentType.current}
              />
            )}
          </div>
        </section>
      )}
      {isShowAddContentModal && (
        <AddMeidaContentModal
          mediaType={assetSeleted.value}
          show={isShowAddContentModal}
          setShow={setIsShowAddContentModal}
          onCreateSuccess={onCreateSuccess}
          isFromMediaFolder={true}
        />
      )}
      {isShowEditContentModal && (
        <EditMediaContentModal
          content={seletedMedia}
          mediaType={assetSeleted.value}
          setShow={setIsShowEditContentModal}
          show={isShowEditContentModal}
          thumbnailContent={seletedMedia?.thumbnailContent}
          onSaveSuccess={onSaveSuccess}
          isFromMediaFolder={true}
          isFromAsset={true}
        />
      )}
    </div>
  );
};

export default CMSAssets;
