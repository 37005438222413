import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { SpinLoading } from "../../../components/svgs/icons";
import { PaintBrushIcon } from "@heroicons/react/24/solid";

export const StaticPagesList = ({ accessMiddleware }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);

  const pages = useSelector((state) => state.configuration.pages);
  const staticPage = pages?.find((page) => page?.name === "static_page");
  const visiblePage = !!staticPage?.props?.visible;

  useEffect(() => {
    accessMiddleware(navigate, "/403cms", () => setLoading(false));
  }, []);

  if (loading) return (
    <div className="w-full h-screen flex justify-center items-center" role="tabpanel">
      <SpinLoading className="!size-12" />
    </div>
  );

  return <>
    <div className="bg-white py-20 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance text-center">
            STATIC PAGES EDITOR
          </p>
        </div>
        <div className="mx-auto mt-12 max-w-2xl sm:mt-12 lg:mt-16 lg:max-w-3xl">
          <div className="max-h-[50vh] overflow-hidden overflow-y-auto sm:ml-10 grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 sm:max-w-3xl sm:grid-cols-2 lg:max-w-3xl lg:grid-cols-3 lg:gap-y-16">
            {visiblePage && (staticPage?.modules || [])?.map((module, _i) => {
              if (!module?.props?.visible) return null;
              return (
                <div key={_i} className="relative pl-12">
                  <div
                    onClick={() => navigate(`/cms/static/${module?.props?.path}/${module?.id}`)}
                    className="text-base/7 leading-8 font-semibold text-gray-900 hover:underline cursor-pointer"
                  >
                    <div className="absolute left-0 top-0 flex size-8 items-center justify-center rounded-lg border-2 border-black">
                      <PaintBrushIcon aria-hidden="true" className="size-4 text-black" />
                    </div>
                    {module?.name}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
    <Outlet />
  </>;
};
