/** @format */

import * as Types from "./type";
import userApi from "../../apis/api/user";
import customerApi from "../../apis/api/customer";
import unitApi from "../../apis/api/unit";
import media from "../../apis/api/media";
import gallery from "../../apis/api/gallery";
import appointment from "../../apis/api/appointment";
import amenities from "../../apis/api/amenities";
import transportOptions from "../../apis/api/transportOption";
import faqApi from "../../apis/api/faq";
import playlistApi from "../../apis/api/playlist";
import folderApi from "../../apis/api/folder";
import configApi from "../../apis/api/config";
import themeApi from "../../apis/api/theme";
import canvasProfilesApi from "../../apis/api/canvas-profiles";
import modal from "../../apis/api/modal";

const actSetListStaff = (data) => ({
  type: Types.GET_LIST_STAFF,
  data,
});

const actSetListUserGroups = (data) => ({
  type: Types.GET_LIST_USER_GROUPS,
  data,
});

const actSetListCustomers = (data) => ({
  type: Types.GET_LIST_CUSTOMERS,
  data,
});

const actSetListBulkOptions = (data) => ({
  type: Types.GET_LIST_BULK_UNIT_MEDIA,
  data,
});

const actSetListAgents = (data) => ({
  type: Types.GET_LIST_AGENTS,
  data,
});

export const actSetListUnits = (data) => ({
  type: Types.GET_LIST_UNITS,
  data,
});

export const actSetListPlaylists = (data) => ({
  type: Types.GET_LIST_PLAYLISTS,
  data,
});

export const actSetFolders = (data) => ({
  type: Types.GET_FOLDERS,
  data,
});

export const actSetActiveConfig = (data) => ({
  type: Types.GET_ACTIVE_CONFIG,
  data,
});

export const actSetConfigs = (data) => ({
  type: Types.LIST_CONFIG,
  data,
});

export const actSetThemes = (data) => ({
  type: Types.GET_THEME,
  data,
});

export const actSetCanvasProfiles = (data) => ({
  type: Types.GET_CANVAS_PROFILES,
  data,
});

export const actSetSelectedCanvasProfileId = (data) => ({
  type: Types.SELECT_CANVAS_PROFILE,
  data,
});

export const actSetLoadingUnits = (data) => ({
  type: Types.GET_LOADING_UNITS,
  data,
});

export const actSetEditableCustomer = (data) => ({
  type: Types.SET_EDITABLE_CUSTOMER,
  data,
});

export const actSetEditableCustomerNote = (data) => ({
  type: Types.SET_EDITABLE_CUSTOMER_NOTE,
  data,
});

export const actSetEditablePropertyColumn = (data) => ({
  type: Types.SET_EDITABLE_PROPERTY_COLUMN,
  data,
});

export const actAddOneCustomer = (data) => ({
  type: Types.ADD_ONE_CUSTOMER,
  data,
});

export const actEditOneCustomer = (data, id) => ({
  type: Types.EDIT_ONE_CUSTOMER,
  data,
  id,
});

export const removeOneCustomer = (id) => ({
  type: Types.REMOVE_ONE_CUSTOMER,
  id,
});

export const actSetListMedia = (data) => ({
  type: Types.GET_LIST_MEDIA,
  data,
});

export const actSetEditableMedia = (data) => ({
  type: Types.SET_EDITABLE_MEDIA,
  data,
});

export const actGetListGalleries = (data) => ({
  type: Types.GET_LIST_GALLERIES,
  data,
});

export const actSetEditableGallery = (data) => ({
  type: Types.SET_EDITABLE_GALLERY,
  data,
});

export const actGetDetailPlaylist = (data) => ({
  type: Types.GET_DETAIL_PLAYLIST,
  data,
});

export const actSetIsShowDeleteGalleryModal = (data) => ({
  type: Types.SET_IS_SHOW_DELETE_GALLERY_MODAL,
  data,
});

export const actGetListAppointment = (data) => ({
  type: Types.GET_LIST_APPOINTMENT,
  data,
});

export const actGetListAmenity = (data) => ({
  type: Types.GET_LIST_AMENITY,
  data,
});

export const actGetListTransportOptions = (data) => ({
  type: Types.GET_LIST_TRANSPORTS,
  data,
});

export const actSetCMSMediaContent = (data) => ({
  type: Types.SET_CMS_MEDIA_CONTENT,
  data,
});

export const actSetMediaState = (data) => ({
  type: Types.SET_LIST_MEDIA_STATE,
  data,
});

export const reqGetListAppointment = (data) => (dispatch) => {
  return appointment.getListAppointment(data).then((res) => {
    dispatch(actGetListAppointment(res));
  });
};

export const reqGetListGalleries = (data) => (dispatch) => {
  return gallery.getListGallery(data).then((res) => {
    dispatch(actGetListGalleries(res));
  });
};

export const reqGetEditableMedia = (mediaId, data) => (dispatch) => {
  if (!mediaId) return dispatch(actSetEditableMedia(false));
  return media.getMediaDetail(mediaId, data).then((res) => {
    dispatch(actSetEditableMedia(res));
  });
};

export const reqGetListMedia = (data) => (dispatch) => {
  return media.getMediaList(data).then((res) => {
    dispatch(actSetListMedia(res));
  });
};

export const reqSetEditableCustomer = (data) => (dispatch) => {
  return dispatch(actSetEditableCustomer(data));
};

export const reqSetEditableCustomerNote = (data) => (dispatch) => {
  return dispatch(actSetEditableCustomerNote(data));
};

export const reqSetEditablePropertyColumn = (data) => (dispatch) => {
  return unitApi.putUnitUpdate(data.id, data).then((res) => {
    dispatch(actSetEditablePropertyColumn(res));
  });
};

export const reqGetListCustomers = (query) => (dispatch) => {
  return customerApi.getCustomerList(query).then((data) => {
    dispatch(actSetListCustomers(data));
  });
};

export const reqGetListStaff = (query) => (dispatch) => {
  return userApi.getUserList(query).then((data) => {
    dispatch(actSetListStaff(data));
  });
};

export const reqGetListUserGroups = (query) => (dispatch) => {
  return userApi.getUserGroupsList(query).then((data) => {
    dispatch(actSetListUserGroups(data));
  });
};

export const reqGetListAgents = (query) => (dispatch) => {
  return userApi.getAgentList(query).then((data) => {
    dispatch(actSetListAgents(data));
  });
};

export const reqGetListUnits = (query) => (dispatch) => {
  dispatch(actSetLoadingUnits(true));
  return unitApi
    .getUnitList(query)
    .then((data) => {
      dispatch(actSetListUnits(data));
      dispatch(actSetLoadingUnits(false));
    })
    .catch(() => {
      dispatch(actSetLoadingUnits(false));
    });
};

export const reqGetDetailPlaylists = (id) => (dispatch) => {
  return playlistApi.getPlaylistDetail(id).then((data) => {
    dispatch(actGetDetailPlaylist(data));
  });
};

export const reqGetPlaylists = (query) => (dispatch) => {
  return playlistApi.getPlaylistList(query).then((data) => {
    dispatch(actSetListPlaylists(data));
  });
};

export const reqGetListFolder = (query) => (dispatch) => {
  return folderApi.getFolders(query).then((res) => {
    if (res && res.data) {
      dispatch(actSetFolders(res.data));
    }
  });
};

export const reqGetConfigs = () => (dispatch) => {
  return configApi.listConfig().then((res) => {
    if (res && res.data) {
      dispatch(actSetConfigs(res));
    }
  });
};

export const reqGetActiveConfig = () => (dispatch) => {
  return configApi.getConfig().then((res) => {
    if (res && res.data) {
      dispatch(actSetActiveConfig(res));
    }
  });
};

export const reqGetThemes = () => (dispatch) => {
  return themeApi.getThemes().then((res) => {
    if (res && res.data) {
      dispatch(actSetThemes(res));
    }
  });
};

export const reqGetCanvasProfiles = () => (dispatch) => {
  return canvasProfilesApi.listCanvasProfiles().then((res) => {
    if (res && res.data) {
      dispatch(actSetCanvasProfiles(res));
    }
  });
};

export const reqGetListAmenities = (query) => (dispatch) => {
  return amenities.getAmenitiesList(query).then((data) => {
    dispatch(actGetListAmenity(data));
  });
};

export const reqGetListTransportOptions = (query) => (dispatch) => {
  return transportOptions.getTransportOptions(query).then((data) => {
    dispatch(actGetListTransportOptions(data));
  });
};

export const reqGetListFAQs = (query) => (dispatch) => {
  return faqApi.getFaqList(query).then((data) => {
    dispatch(actSetListFAQs(data));
  });
};

export const reqGetListBulkOptions = () => (dispatch) => {
  return media.getMediaBulUnitOptions().then((data) => {
    dispatch(actSetListBulkOptions(data));
  });
};

export const actSetListFAQs = (data) => ({
  type: Types.GET_LIST_FAQS,
  data,
});

export const reqOnChangePlaylistOfModal =
  (modalId, data, onSuccess, onError) => (dispatch) => {
    return modal
      .updateModal(modalId, data)
      .then((res) => {
        onSuccess && onSuccess();
      })
      .catch(() => {
        onError && onError();
      });
  };
