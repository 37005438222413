/** @format */

import {
  fetch3DSettings,
  fetchAssetsList,
  fetchHotspotsList,
  fetchPagesSettings,
} from "../../reduxs/scene/action";
import {
  reqIsShowGallery,
  reqIsShowFloorplan,
  reqSetSelectedUnit,
  reqSetIsShowFilter,
  reqIsShowUnitDetail,
  reqSetIsShowPrecinctDetail,
} from "../../reduxs/unit-explore/action";
import {
  reqSetPage,
  reqSetIsExpandNav,
  reqSetIsShowImmerse,
  reqSetIsTransparent,
  reqSetIsPresentation,
  reqSetActiveTransportOption,
  reqSetIsShowEndGuideTenantSession,
  reqSetActiveEndGuideTenantSessionId,
  reqSetActiveTransportOptionDistricts,
} from "../../reduxs/home/action";
import {
  reqSetExploreModal,
  reqSetIsShowExploreModal,
  reqSetIsShowAmenityVirtualTour,
} from "../../reduxs/explore-modal/action";
import {
  reqSetIsShowReplayVideo,
  reqSetIsShowPrecinctExploreDetail,
} from "../../reduxs/precinct-explore/action";
import { toast } from "react-toastify";
import socket from "../../helper/socket";
import ReactuiPages from "./reactui-pages";
import { Quaternion, Vector3 } from "three";
import TopNav from "../../components/top-nav";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import { setColor2 } from "../../helper/threeHeper";
import { PAGES_ENUM } from "../../constants/modules";
import RightPanel from "../../components/right-panel";
import VideoIntro from "../../components/video-intro";
import { useDispatch, useSelector } from "react-redux";
import { threePosition } from "../../helper/threeHeper";
import { actIntroduction } from "../../reduxs/scene/action";
import { reqSetCustomerProfile } from "../../reduxs/user/action";
import BottomNavigation from "../../components/bottom-navigation";
import { GLTF3DLoader } from "../../components/3d-scene/GLTF3DLoader";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { actSetActiveAmenity } from "../../reduxs/amenities/action";
import { isTouchDevice, clearFilterUnit } from "../../helper/utils";
import BookingAppointment from "../../components/booking-appointment";
import VirtualModal from "../../components/home-gallery/virtual-modal";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import React, { useCallback, useEffect, useState, Suspense } from "react";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import {
  reqSetDataStaticType1,
  reqSetDataStaticType2,
} from "../../reduxs/gallery/action";
import {
  REACTUI_PAGES,
  LAYERS,
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
} from "../../constants/options";
import { reqGetActiveConfig } from "../../reduxs/cms/action";

const ReactUI = (props) => {
  const {
    setIsIntroduction,
    controls,
    refScene,
    setActiveObjectIds,
    resetActiveColorModel,
    activeObjectIds,
    isPresentation,
    setOrbitConfig,
    orbitConfigTemplate
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const meshes = useSelector((state) => state.scene.meshes);
  const gltfModels = useSelector((state) => state.scene.gltfModels);
  const gltfHelpers = useSelector((state) => state.scene.gltfHelpers);
  const pages = useSelector((state) => state.configuration.pages);
  const reactUiPage = useSelector((state) => state.home.reactUiPage);
  const authUser = useSelector((state) => state.user.data);
  const customer = useSelector((state) => state.user.customer);
  const isLoading = useSelector((state) => state.scene.isLoading);
  const isNavExpand = useSelector((state) => state.home.isNavExpand);
  const pagesSettings = useSelector((state) => state.scene.pagesSettings);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowVirtualModal = useSelector(
    (state) => state.gallery.isShowVirtualModal
  );
  const isShowReplayVideo = useSelector(
    (state) => state.precinctExplore.isShowReplayVideo
  );
  const transportOptionDistricts = useSelector(
    (state) => state.home.transportOptionDistricts
  );
  const isShowBookingAppointmentForm = useSelector(
    (state) => state.home.isShowBookingAppointmentForm
  );
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const [isResetModel, setResetModel] = useState(false);

  const [init, setInit] = useState(false);

  const resetOrbits = () => setOrbitConfig({...orbitConfigTemplate})

  useEffect(() => {
    if (!activeConfig) loadConfig();
  }, [activeConfig]);

  const loadConfig = async () => {
    dispatch(reqGetActiveConfig());
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const customerID = urlParams.get("customer") || "";
    if (customerID) {
      handleResetCustomer();
    }

    isPresentation && dispatch(reqSetIsPresentation(true));

    window.addEventListener("keydown", handleRefresh);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      window.removeEventListener("keydown", handleRefresh);
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);

  useEffect(() => {
    const establishSocket = (authUser) => {
      if (!authUser) return;
      socket.connect(authUser);
      if (isPresentation) {
        socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
        socket.on(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, listenerCameraAction);
      }
    };

    establishSocket(authUser);

    const root = window.document.getElementById("root");

    const handleMouseMove = _.debounce((e) => {
      if (!socket.socket.connected) {
        establishSocket(authUser);
      }
    }, 150);

    const touchDevice = isTouchDevice();

    if (touchDevice) {
      root.addEventListener("touchstart", handleMouseMove);
    } else {
      root.addEventListener("mouseover", handleMouseMove);
    }

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }

      if (touchDevice) {
        root.removeEventListener("touchstart", handleMouseMove);
      } else {
        root.removeEventListener("mouseover", handleMouseMove);
      }

      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      socket.off(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, listenerCameraAction);
    };
  }, [authUser?.id, isPresentation]);

  useEffect(() => {
    function resetModel() {
      setResetModel(true);
    }
    document.addEventListener("REST_STATE", resetModel);
    return () => {
      document.removeEventListener("REST_STATE", resetModel);
    };
  }, []);

  useEffect(() => {
    if (selectedUnit?.["3d_mesh"]) {
      setActiveObjectIds([selectedUnit?.["3d_mesh"]]);
    }
  }, [selectedUnit]);

  const listenerSharedUIAction = ({ content }) => {
    const action = content?.action;
    const data = content?.data;

    if (action == ACTION_NAME.CLICK_CUBE_MENU) {
      return handleClickCube();
    }
    if (action == ACTION_NAME.CLICK_EXPLORE_MENU) {
      return handleClickResidences();
    }
    if (action == ACTION_NAME.CLICK_GALLERY_MENU) {
      return handleClickGallery();
    }
    if (action == ACTION_NAME.CLICK_GALLERY_B) {
      return handleClickGalleryB();
    }
    if (action == ACTION_NAME.CLICK_GALLERY_A) {
      return handleClickGalleryA();
    }
    if (action == ACTION_NAME.CLICK_LOCATION_MENU) {
      return handleLocation();
    }
    if (action == ACTION_NAME.CLICK_AMENITY_MENU) {
      return handleAmenities();
    }
    if (action == ACTION_NAME.CLICK_TRANSPORT_MENU) {
      return handleTransportOptions();
    }
    if (action == ACTION_NAME.CLICK_NEIGHBORHOOD_MENU) {
      return handleNeighborhood(data);
    }
    if (action == ACTION_NAME.CLICK_PPG_MENU) {
      return handlePpg();
    }
    if (action == ACTION_NAME.CLICK_REPLAY_VIDEO) {
      return onReplayVideo();
    }
    if (action == ACTION_NAME.CLICK_SKIP_REPLAY_VIDEO) {
      return onSkipReplayVideo();
    }
    if (action == ACTION_NAME.CLICK_UNIT) {
      return handleUnitClick(data?.unit);
    }
    if (action === ACTION_NAME.TOAST_MESSAGE) {
      toast[data.type](data.message, {
        toastId: "socket-toast",
      });
      return;
    }
    if (action === ACTION_NAME.RESET_CUSTOMER) {
      handleResetCustomer();
    }
  };

  const listenerCameraAction = ({ content }) => {
    if (controls.current != null) {
      const currentPos = controls.current?.object?.position;
      // get last and new position vectors
      let lastPosition = new Vector3(
        currentPos?.x,
        currentPos?.y,
        currentPos?.z
      );
      let newPosition = new Vector3(
        content?.position?.x,
        content?.position?.y,
        content?.position?.z
      );

      // get new quaternion
      const newQuaternion = new Quaternion(
        content?.quaternion?.x,
        content?.quaternion?.y,
        content?.quaternion?.z,
        content?.quaternion?.w
      );

      controls.current.object.position.copy(newPosition);
      controls.current.object.quaternion.copy(newQuaternion);
      controls.current.object.zoom = content.zoom;
      controls?.current?.object?.updateProjectionMatrix();
    }
  };

  const handleRefresh = (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    const customerID = urlParams.get("customer") || "";
    if (event.key === "F5" || event.type === "beforeunload") {
      if (!isPresentation && customerID) {
        handleResetCustomer();
        event.returnValue = "";
        return null;
      }
    }
  };

  const handleResetCustomer = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.RESET_CUSTOMER);
    }
    dispatch(reqSetPage(REACTUI_PAGES.ONBOARD_PAGE));
    navigate(isPresentation ? "/presentation" : "/");
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(""));
  };

  const HandleSetActivePage = React.memo((props) => {
    if (props.pagesSettings == null) {
      return <div />;
    }
    const settings = props.pagesSettings.filter(
      (setting) => setting.name == reactUiPage
    );
    if (controls.current == null || settings.length == 0) {
      return <div />;
    }
    controls.current.currentPage = reactUiPage;
    const setting = settings[0];

    // modular
    const module = props.pages
      ?.find((p) => p.name === PAGES_ENUM.INTERACTIVE_3D && !!p?.props?.visible)
      ?.modules?.find((m) => m.props?.reactui_page === setting.name);

    const camera = module?.props?.camera;
    const target = module?.props?.camera_look_at_position;
    const showLayers = module?.props?.show_layers;
    const enableLayers = module?.props?.enable_layers;
    const visible = module?.props?.visible;

    // active objects
    if (setting.active_objects != null && setting.active_objects.length > 0) {
      setActiveObjectIds(setting.active_objects);
    }

    // camera position & target
    if (camera && target && visible && !selectedUnit && !isPresentation) {
      const camPosition = threePosition(camera);
      const camLookAtPosition = threePosition(target);
      controls.current?.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition,
        () => {}
      );
    }

    // layers
    if (Array.isArray(showLayers) && Array.isArray(enableLayers)) {
      controls.current.hideAll();
      controls.current.disableAll();

      controls.current.showAndEnableLayer(0);
      if (visible) {
        for (let i = 0; i < showLayers.length; i += 1) {
          controls.current.showLayer(showLayers[i]);
        }
      } else {
        controls.current.showLayer(0);
      }
      for (let i = 0; i < enableLayers.length; i += 1) {
        controls.current.enableLayer(enableLayers[i]);
      }
    }
    setResetModel(false);
    return <div />;
  });
  HandleSetActivePage.displayName = "HandleSetActivePage";

  const handleMoveCamera = (meshObject, onCompleted = () => {}) => {
    console.log("handleMoveCamera");
    let [camPos, camTarget] = [null, null];
    const matchObject = gltfModels?.find(
      (obj) =>
        String(obj.name).toLowerCase() === String(meshObject.name).toLowerCase()
    );
    if (!matchObject) {
      [camPos, camTarget] = [
        gltfHelpers?.find(
          (hlp) =>
            hlp.name?.toLowerCase() ===
            meshObject.camVectors?.position?.toLowerCase()
        )?.position,
        gltfHelpers?.find(
          (hlp) =>
            hlp.name?.toLowerCase() ===
            meshObject.camVectors?.target?.toLowerCase()
        )?.position,
      ];
    } else {
      [camPos, camTarget] = [
        matchObject?.camHelpers?.position || null,
        matchObject?.camHelpers?.target || null,
      ];
    }
    if (camPos && camTarget) {
      controls.current?.lookAtAndMovePosition(
        camTarget || threePosition(meshObject?.xyz_position),
        camPos,
        onCompleted
      );
    }
  };

  const handleClickCube = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_CUBE_MENU);
    }
    resetState();
    dispatch(reqSetPage(REACTUI_PAGES.LANDING_PAGE));
    dispatch(reqSetIsExpandNav(!isNavExpand));
  };

  const handleUnitClick = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT, {
        unit: item,
      });
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
      const meshConfig = meshes?.find(
        (m) =>
          String(m.name).toLowerCase() ===
          String(item?.["3d_mesh"]).toLowerCase()
      );
      if (controls?.current && meshConfig) {
        handleMoveCamera(meshConfig);
      }
    }
    dispatch(reqIsShowUnitDetail(true));
    dispatch(reqSetSelectedUnit(item.id));
    dispatch(reqSetIsShowFilter(false));
  };

  const handleClickAmenity = (item) => {
    if (!isPresentation) {
      // socket.emitUIActionEvent(ACTION_NAME.CLICK_AMENITY_ITEM, {
      //   unit: item,
      // });
      // socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
      const mesh = meshes?.find(
        (mesh) => mesh.name === String(item?.["3d_mesh"]).toLowerCase()
      );
      if (mesh) {
        setActiveObjectIds([item?.["3d_mesh"]]);
        if (controls?.current) handleMoveCamera(mesh);
      }
    }
    const module = pages
      ?.find((p) => p.name === PAGES_ENUM.INTERACTIVE_3D && !!p?.props?.visible)
      ?.modules?.find(
        (m) => m.props?.reactui_page === REACTUI_PAGES.AMENITIES_PAGE
      );
    if (!item && module) {
      const camPosition = threePosition(module?.props?.camera);
      const camLookAtPosition = threePosition(
        module?.props?.camera_look_at_position
      );
      controls.current?.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition,
        () => {}
      );
    }
    if (item) {
      dispatch(actSetActiveAmenity(item));
      dispatch(reqSetExploreModal(item.modal.id));
      dispatch(reqSetIsShowExploreModal(true));
    } else {
      dispatch(actSetActiveAmenity(null));
      dispatch(reqSetExploreModal(null));
      dispatch(reqSetIsShowExploreModal(false));
    }
  };

  const handleClickResidences = () => {
    setActiveObjectIds([]);
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_EXPLORE_MENU);
    }
    clearFilterUnit("auto_available");
    dispatch(reqSetIsTransparent(false));
    dispatch(reqSetIsShowFilter(true));
    dispatch(unitExploreAct.reqFilterUnitAvailability([]));
    resetState();
    dispatch(reqSetPage(REACTUI_PAGES.UNIT_EXPLORER_PAGE));
  };

  const handleClickGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_GALLERY_MENU);
    }
    resetState();
    dispatch(reqSetPage(REACTUI_PAGES.GALLERY_LANDING_PAGE));
  };

  const handleClickGalleryB = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_GALLERY_B);
    }
    resetState();
    dispatch(reqSetPage(REACTUI_PAGES.GALLERY_PAGE_TYPE_B));
  };

  const handleClickGalleryA = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_GALLERY_A);
    }
    resetState();
    dispatch(reqSetPage(REACTUI_PAGES.GALLERY_PAGE_TYPE_A));
  };

  const handleLocation = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_LOCATION_MENU);
    }
    resetState();
    dispatch(reqSetPage(REACTUI_PAGES.LOCATION_PAGE));
  };
  const handleAmenities = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_AMENITY_MENU);
    }
    resetState();
    dispatch(reqSetPage(REACTUI_PAGES.AMENITIES_PAGE));
  };
  const handleTransportOptions = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_TRANSPORT_MENU);
    }
    resetState();
    dispatch(reqSetPage(REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE));
  };
  const handleNeighborhood = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_NEIGHBORHOOD_MENU, item);
    }
    resetState();
    dispatch(
      reqSetDataStaticType1({ sections: item?.sections, path: item.path })
    );
    dispatch(reqSetPage(REACTUI_PAGES.NEIGHBORHOOD_PAGE));
  };
  const handlePpg = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_PPG_MENU);
    }
    resetState();
    dispatch(reqSetDataStaticType2(item?.sections));
    dispatch(reqSetPage(REACTUI_PAGES.PPG_PAGE));
  };

  const handleClickEndGuideTenantSession = (customerID) => {
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    resetState();
    dispatch(reqSetActiveEndGuideTenantSessionId(customerID));
    dispatch(reqSetIsShowEndGuideTenantSession(true));
    dispatch(reqSetPage(REACTUI_PAGES.END_GUIDE_TENANT_SESSION_PAGE));
  };

  const handleClickTenantEndSession = () => {
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    dispatch(reqSetPage(REACTUI_PAGES.ONBOARD_PAGE));
    navigate("/");
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(""));
  };

  const handleClickTransportOptions = (
    transportOption,
    ids,
    selected = false
  ) => {
    if (refScene.current == null) {
      return;
    }
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_TRANSPORT_ITEM, {
        item: transportOption,
      });
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
      const mesh = meshes?.find((mesh) =>
        transportOption?.["3d_meshes"].includes(mesh.name)
      );
      if (mesh && controls?.current && !selected) {
        handleMoveCamera(mesh);
      }
    }
    const module = pages
      ?.find((p) => p.name === PAGES_ENUM.INTERACTIVE_3D && !!p?.props?.visible)
      ?.modules?.find(
        (m) => m.props?.reactui_page === REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE
      );
    if (!ids?.length && module) {
      const camPosition = threePosition(module?.props?.camera);
      const camLookAtPosition = threePosition(
        module?.props?.camera_look_at_position
      );
      controls.current?.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition,
        () => {}
      );
    }
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  };

  const handleClickTransportOptionDistricts = useCallback((ids, model) => {
    if (refScene.current == null) {
      return;
    }
    resetActiveColorModel(model);
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const resetState = () => {
    if (controls.current != null) {
      controls.current.needReloadSelectedHotspotId = true;
    }

    if (refScene.current != null) {
      transportOptionDistricts.forEach((tp) => {
        tp.hidden_when_not_selected.forEach((id) => {
          let object = refScene.current?.getObjectByName(id);
          if (object != null) {
            object.layers.set(object.userData.layer ?? LAYERS.DISABLE);
          }
        });
      });
    }
    resetActiveColorModel({});
    resetOrbits();
    setActiveObjectIds([]);
    dispatch(reqSetActiveTransportOption([]));
    dispatch(reqSetActiveTransportOptionDistricts([]));
    dispatch(reqSetSelectedUnit(""));
    dispatch(reqIsShowGallery(false));
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqIsShowFloorplan(false));
    dispatch(reqSetIsShowPrecinctExploreDetail(false));
    dispatch(reqSetIsShowPrecinctDetail(false));
    dispatch(reqSetActivePrecinctID(null));
    dispatch(reqSetIsShowEndGuideTenantSession(false));
    dispatch(reqSetIsShowReplayVideo(false));
    dispatch(reqSetIsShowImmerse(false));
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    dispatch(reqSetIsShowAmenityVirtualTour(false));
  };

  const handleReset3DFilter = () => {
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(unitExploreAct.reqFilterUnitBathroom([]));
    dispatch(unitExploreAct.reqFilterUnitAvailability([]));
    dispatch(unitExploreAct.reqFilterUnitAspect(""));
    dispatch(unitExploreAct.reqFilterUnitPrice(""));
    dispatch(unitExploreAct.reqFilterUnitLotSize(""));
  };

  const onReplayVideo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_REPLAY_VIDEO);
    }
    dispatch(reqSetIsShowReplayVideo(true));
  };

  const onSkipReplayVideo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_SKIP_REPLAY_VIDEO);
    }
    dispatch(reqSetIsShowReplayVideo(false));
  };

  useEffect(() => {
    if (init || reactUiPage === REACTUI_PAGES.SESSION_PAGE) {
      return;
    }

    setInit(true);
    dispatch(fetch3DSettings());
    // dispatch(fetchAssetsList());
    dispatch(fetchHotspotsList());
    dispatch(fetchPagesSettings());
  }, [init, reactUiPage]);

  return (
    <>
      <HandleSetActivePage
        pagesSettings={pagesSettings}
        reactUiPage={reactUiPage}
        pages={pages}
        isResetModel={isResetModel}
      />

      <TopNav
        isPresentation={isPresentation}
        isShow={reactUiPage !== REACTUI_PAGES.ONBOARD_PAGE}
        isLoading={isLoading}
        handleClickCube={handleClickCube}
        handleClickGalleryB={handleClickGalleryB}
        handleClickGalleryA={handleClickGalleryA}
        handleLocation={handleLocation}
        handleNeighborhood={handleNeighborhood}
        handlePpg={handlePpg}
        handleClickResidences={handleClickResidences}
        handleAmenities={handleAmenities}
        handleTransportOptions={handleTransportOptions}
      />

      <BottomNavigation
        isPresentation={isPresentation}
        handleClickEndGuideTenantSession={handleClickEndGuideTenantSession}
        handleClickTenantEndSession={handleClickTenantEndSession}
        setActiveObjectIds={setActiveObjectIds}
        resetState={resetState}
      />

      {reactUiPage == REACTUI_PAGES.ONBOARD_PAGE && (
        <Loading
          isPresentation={isPresentation}
          isLoading={isLoading}
          setIsIntroduction={setIsIntroduction}
        />
      )}

      <RightPanel reactuiPage={reactUiPage} isPresentation={isPresentation} />

      <ReactuiPages
        refScene={refScene}
        isPresentation={isPresentation}
        activeObjectIds={activeObjectIds}
        setActiveObjectIds={setActiveObjectIds}
        handleUnitClick={handleUnitClick}
        handleClickAmenity={handleClickAmenity}
        handleClickTransportOptions={handleClickTransportOptions}
        handleClickTransportOptionDistricts={
          handleClickTransportOptionDistricts
        }
        resetState={resetState}
      />

      <TransitionGroup>
        {isShowVirtualModal && (
          <CSSTransition timeout={200} classNames="fade-item">
            <VirtualModal />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowBookingAppointmentForm && (
          <CSSTransition timeout={200} classNames="fade-item">
            <BookingAppointment />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowReplayVideo && reactUiPage !== REACTUI_PAGES.ONBOARD_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <VideoIntro
              isPresentation={isPresentation}
              onSkipReplayVideo={onSkipReplayVideo}
            />
          </CSSTransition>
        )}
      </TransitionGroup>

      <Suspense fallback={null}>
        <GLTF3DLoader />
      </Suspense>
    </>
  );
};

export default ReactUI;
