import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "../components/Create";
import {
  reqGetListAgents,
  reqGetListCustomers,
} from "../../../reduxs/cms/action";
import EditCustomerModal from "./EditCustomerModal";
import AddCustomerModal from "./AddCustomerModal";
import { toast } from "react-toastify";
import CustomerFavoritesModal from "./CustomerFavoritesModal";
import customersAPI from "../../../apis/api/customer";
import { ReactComponent as ArrowRight } from "../../../assets/images/arrow-right.svg";
import CmsPageHeader from "../components/PageHeader";
import { useNavigate } from "react-router-dom";
import { SpinLoading } from "../../../components/svgs/icons";

export const PurchaseOptions = [
  { label: "Primary Residence", value: "Primary Residence" },
];

const Row = ({ data, onEditCustomer, onViewFavorite, onSaveSuccess }) => {
  const [show, setShow] = useState(false);

  const handleCopyURL = () => {
    if (!data) return;
    const url =
      data?.uniqueUrl ||
      `${window.location.origin}/post-engagement/${data?.id}`;
    navigator.clipboard.writeText(url);
    toast.success("Copied customer session url!");
  };

  const handleUpdateActive = async (e) => {
    if (!data) return;

    try {
      const updated = {
        isActive: e?.target?.checked || false,
      };
      const res = await customersAPI.updateCustomer(data.id, updated);
      if (res.data) {
        toast.success("Customer updated successfully!");
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className="grid-cols-[15%_15%_23%_13%_12%_12%_calc(10%_-_35px)_35px] h-[35px] grid border-b-[1px] border-solid border-[#C5C5C566]">
      <div className="text-black font-poppins text-sm leading-[10px] flex items-center inline-block align-middle">
        {data?.firstname}
      </div>
      <div className="text-black font-poppins text-sm leading-[10px] flex items-center inline-block align-middle">
        {data?.surname}
      </div>
      <div className="text-black font-poppins text-sm leading-[10px] flex items-center inline-block align-middle">
        {data?.email}
      </div>
      <div className="text-black font-poppins text-sm leading-[10px] flex items-center inline-block align-middle">
        {data?.agent ? `${data?.agent?.firstname} ${data?.agent?.surname}` : ""}
        
      </div>
      <div
        className="cursor-pointer gap-[20px] justify-start flex items-center"
        onClick={() => onViewFavorite?.(data)}
      >
        <div className="text-sm font-medium">VIEW</div>
        <div>
          <ArrowRight />
        </div>
      </div>
      <div
        className="flex gap-[20px] cursor-pointer justify-start items-center"
        onClick={() => handleCopyURL()}
      >
        <div className="text-sm font-medium">COPY</div>
        <div>
          <ArrowRight />
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex items-center h-full">
          <input
            className={`m-0 p-0 bg-[#CCCCCC59] w-[44px] h-[20px] !switch-bg-image !border-none bg-black rounded-[50px] checked:bg-[right] !bg-no-repeat !shadow-none cursor-pointer !checked:bg-[#000000] !checked:bg-auto !bg-auto transition-[300ms_ease-in-out]`}
            type="checkbox"
            id="customer-state-1"
            defaultChecked={data?.isActive}
            onChange={handleUpdateActive}
          />
          <label className="form-check-label" htmlFor="customer-state-1" />
        </div>
      </div>
      <div onClick={() => onEditCustomer?.(data)} className="flex items-center">
        <div className="text-right rounded-[50px] bg-[#D3D0D159] w-[31px] h-[10px] cursor-pointer flex justify-center items-center">
          <img className="w-full h-full" src="/icons/edit-button.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

const CMSCustomers = ({ accessMiddleware }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isShowEditCustomerModal, setIsShowEditCustomerModal] = useState(false);
  const [isShowAddCustomerModal, setIsShowAddCustomerModal] = useState(false);
  const [isShowCustomerFavoritesModal, setIsShowCustomerFavoritesModal] =
    useState(false);
  const [seletedCustomer, setSelectedCustomer] = useState(null);
  const [search, setSearch] = useState("");
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [loading, setLoading] = useState(true);

  const customers = useSelector((state) => state.cms.customers);

  useEffect(() => {
    getListCustomers();
  }, [search, isSortAsc]);

  useEffect(() => {
    accessMiddleware(navigate, "/403cms", () => setLoading(false));
    getListCustomers();
    getListAgents();
  }, []);

  const getListCustomers = () => {
    dispatch(
      reqGetListCustomers({
        search,
        sortBy: JSON.stringify({
          name: isSortAsc ? 1 : -1,
        }),
      })
    );
  };

  const getListAgents = () => {
    dispatch(
      reqGetListAgents({
        sortBy: JSON.stringify({
          name: 1,
        }),
        isActive: true,
      })
    );
  };

  const onSaveSuccess = () => {
    getListCustomers();
  };

  const onCreateSuccess = () => {
    getListCustomers();
  };

  const onEditCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsShowEditCustomerModal(true);
  };

  const onAddNewCustomer = () => {
    setIsShowAddCustomerModal(true);
  };

  const onViewFavorite = (customer) => {
    setSelectedCustomer(customer);
    setIsShowCustomerFavoritesModal(true);
  };

  const onSearch = (e) => {
    setSearch(e.target?.value);
  };

  if (loading) return (
    <div className="w-full h-screen flex justify-center items-center" role="tabpanel">
      <SpinLoading className="!size-12" />
    </div>
  );

  const renderListProperty = () => {
    return (customers || []).map((item, index) => {
      return (
        <Row
          key={item.id}
          data={item}
          index={index}
          onEditCustomer={onEditCustomer}
          onViewFavorite={onViewFavorite}
          onSaveSuccess={onSaveSuccess}
        />
      );
    });
  };

  const renderContent = () => {
    return (
      <div className="text-[#1A1A1A] text-[24px] min-w-[1150px] w-full h-full overflow-auto">
        <div className="grid grid-cols-[15%_15%_23%_13%_12%_12%_calc(10%_-_35px)_35px] pb-[60px] pr-[45px]">
          <div className="text-black font-poppins text-sm font-bold leading-[10px] uppercase inline-block align-middle">
            FIRST NAME
          </div>
          <div className="text-black font-poppins text-sm font-bold leading-[10px] uppercase inline-block align-middle">
            LAST NAME
          </div>
          <div className="text-black font-poppins text-sm font-bold leading-[10px] uppercase inline-block align-middle">
            EMAIL
          </div>
          <div className="text-black font-poppins text-sm font-bold leading-[10px] uppercase inline-block align-middle">
            AGENT
          </div>
          <div className="text-black font-poppins text-sm font-bold leading-[10px] uppercase inline-block align-middle">
            FAVORITES
          </div>
          <div className="text-black font-poppins text-sm font-bold leading-[10px] uppercase inline-block align-middle">
            URL
          </div>
          <div className="text-black font-poppins text-sm font-bold leading-[10px] uppercase inline-block align-middle">
            ACTIVE
          </div>
          <div className="text-black font-poppins text-sm font-bold leading-[10px] uppercase inline-block align-middle"></div>
        </div>
        <div className="h-[calc(100vh-75px-131px-80px-200px)] overflow-auto pr-[40px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
          {renderListProperty()}
        </div>
      </div>
    );
  };

  return (
    <div className="page-container">
      <CmsPageHeader title={"CUSTOMERS"} />
      <div className="page-body m-0 pt-[80px] px-[108px] pb-0 flex flex-col items-center overflow-auto h-[calc(100vh-131px-80px]">
        {renderContent()}
        <div className="w-full" onClick={onAddNewCustomer}>
          <CreateIcon createCustomer={true} />
        </div>
      </div>
      {isShowEditCustomerModal && (
        <EditCustomerModal
          show={isShowEditCustomerModal}
          setShow={setIsShowEditCustomerModal}
          customer={seletedCustomer}
          onSaveSuccess={onSaveSuccess}
        />
      )}
      {isShowAddCustomerModal && (
        <AddCustomerModal
          show={isShowAddCustomerModal}
          setShow={setIsShowAddCustomerModal}
          onCreateSuccess={onCreateSuccess}
        />
      )}
      {isShowCustomerFavoritesModal && (
        <CustomerFavoritesModal
          show={isShowCustomerFavoritesModal}
          setShow={setIsShowCustomerFavoritesModal}
          customer={seletedCustomer}
        />
      )}
    </div>
  );
};
export default CMSCustomers;
