/** @format */

import React, { useEffect, useMemo, useState } from "react";
import SearchIcon from "../Search";
import _ from "lodash";
import { toast } from "react-toastify";
import icImg from "../../../../assets/images/cms/icImg.webp";
import icEdit from "../../../../assets/images/cms/edit.png";
import closeIcon from "../../../../assets/images/close-white.svg";
import { useDispatch, useSelector } from "react-redux";
import LoadingTable from "../../configuration/components/Table/LoadingTable";
import EmptyTable from "../../configuration/components/Table/EmptyTable";
import UnitEditModal, { availabilityOptions } from "./edit-modal";
import TrashIcon from "../../configuration/components/svgs/TrashIcon";
import { Button, Modal } from "../../components/commons";
import unitApi from "../../../../apis/api/unit";
import { reqGetListUnits } from "../../../../reduxs/cms/action";
import UpsertGalleryPlaylist from "../../gallery/upsertGalleryPlaylist";
import { reqGetFeatureList } from "../../../../reduxs/page-configuration/action";
import { columnMap } from "../utils";

const typeMapping = {
  images: "imagePlaylistOverride",
  floorplans: "floorplanPlaylistOverride",
  viewlines: "viewlinePlaylistOverride",
  "360image": "360ImagePlaylistOverride",
};

export const typePlaylistMapping = {
  images: "imagePlaylist",
  floorplans: "floorplanPlaylist",
  viewlines: "viewlinePlaylist",
  "360image": "360ImagePlaylist",
};

const UnitData = ({ onSearch, setSelectedData }) => {
  const { units, loadingUnits } = useSelector((state) => state.cms);
  const dispatch = useDispatch();

  const [isShowUnitModal, setIsShowUnitModal] = useState(false);
  const [isShowEditUnitModal, setIsShowEditUnitModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [unitId, setUnitId] = useState("");
  const [unitEditId, setUnitEditId] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);

  const override = useMemo(
    () =>
      units.find((unit) => unit.id === unitId)?.[typeMapping[modalType]] || "",
    [units, unitId, modalType]
  );

  const [playlistId, setPlaylistId] = useState("");
  const unitName = useMemo(
    () => units.find((unit) => unit.id === unitId)?.name || "",
    [units, unitId]
  );
  useEffect(() => {
    const unit = units.find((unit) => unit.id === unitId);
    let playlistId;
    switch (modalType) {
      case "images":
        playlistId = unit.imagePlaylist?.id;
        return setPlaylistId(playlistId);
      case "floorplans":
        playlistId = unit.floorplanPlaylist?.id;
        return setPlaylistId(playlistId);
      case "viewlines":
        playlistId = unit.viewlinePlaylist?.id;
        return setPlaylistId(playlistId);
      case "360image":
        playlistId = unit["360ImagePlaylist"]?.id;
        return setPlaylistId(playlistId);
      default:
        break;
    }
  }, [units, modalType, unitId]);

  const openUnitModal = (unitId, modalType) => {
    setIsShowUnitModal(true);
    setModalType(modalType);
    setUnitId(unitId);
  };

  const openEditUnitModal = (unitId) => {
    setIsShowEditUnitModal(true);
    setUnitEditId(unitId);
  };

  const onChangePlaylist = async (e) => {
    setPlaylistId(e.id);
    try {
      const body = {
        [typePlaylistMapping[modalType]]: e.id,
      };
      const res = await unitApi.putUnitUpdate(unitId, body);
      if (res.data) {
        toast.success("Edit playlist successfully!");
        dispatch(reqGetListUnits());
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleDelete = async ({ id, onSuccess, onError }) => {
    setLoadingDelete(true);
    try {
      const res = await unitApi.deleteUnit(id);
      if (res) {
        toast.success("Delete unit successfully");
        onSuccess && onSuccess();
      }
    } catch (error) {
      onError && onError();
      toast.error("Delete unit failed");
    } finally {
      setLoadingDelete(false);
    }
  };
  const features = useSelector((state) => state.configuration.features);

  const dataSource = useMemo(
    () =>
      features?.length
        ? _.flatMap(features, (feature) =>
            feature.components
              .filter((el) => !!el.props.visible)
              ?.sort((a, b) => a?.props?.order - b?.props?.order)
              ?.map((component) => ({
                ...component,
                nameFeature: feature.name,
                // idModule: moduleState?.id,
              }))
          )?.reduce((acc, curr) => {
            if (
              curr.props?.group &&
              acc.some((item) => item.props?.group === curr.props.group)
            ) {
              return acc;
            }
            return acc.concat(curr);
          }, [])
        : [],
    [features]
  );

  return (
    <div className="bg-[rgb(244,244,244)] h-screen p-4">
      <div className="bg-white p-4 rounded-md">
        <SearchIcon
          callbackOnInputChange={onSearch}
          wrapperClassName="flex justify-end mb-2"
        />
        <div className=" h-[calc(100vh-260px)]  overflow-y-scroll">
          <table
            className="w-full border-collapse border-spacing-y-2 table-auto"
            id="unit-data-table"
          >
            <thead>
              <tr className="text-sm font-semibold">
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Unit Number
                </th>
                <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}>
                  Facing
                </th>
                <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}>
                  Unit type
                </th>
                <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}>
                  Level
                </th>
                {dataSource
                  .filter((el) => !!columnMap[el.name])
                  .map((el) => (
                    <th
                      className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                    >
                      {columnMap[el.name]}
                    </th>
                  ))}
                <th
                  className={`${thCss} text-center font-bold sticky z-[1] top-0 bg-gray-100 w-[55px]`}
                >
                  Edit
                </th>
                <th
                  className={`${thCss} text-center font-bold sticky z-[1] top-0 bg-gray-100 w-[16px]`}
                >
                  Delete
                </th>
              </tr>
            </thead>
            {loadingUnits && (
              <tbody className="h-[500px]">
                <tr>
                  <td colSpan="10" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <LoadingTable />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!loadingUnits && !units?.length && (
              <tbody className="h-[500px]">
                <tr>
                  <td
                    colSpan="10"
                    className="text-center py-4 border border-t-0 border-gray-300"
                  >
                    <div className="flex justify-center items-center">
                      <EmptyTable />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!loadingUnits && (
              <tbody>
                {(units || [])?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className="text-sm transition-all cursor-grab rounded-[5px]"
                    >
                      <td className={thCss}>Residence {_.get(item, "name")}</td>
                      <th className={`${thCss} capitalize`}>
                        {item?.facing ? item?.facing : '-'}
                      </th>
                      <th className={`${thCss} capitalize`}>
                        {item?.unitType ? item?.unitType : '-'}
                      </th>
                      <th className={`${thCss} capitalize`}>
                        {item?.level ? item?.level : '-'}
                      </th>
                      {dataSource.find((e) => e.name === "filter_status") && (
                        <td className={`${thCss} capitalize`}>
                          {
                            availabilityOptions.find(
                              (it) => it.value === item?.availabilityStatus
                            ).name
                          }
                        </td>
                      )}
                      {dataSource.find((e) => e.name === "filter_price") && (
                        <td className={thCss}>{item?.price}</td>
                      )}
                      {dataSource.find((e) => e.name === "filter_size") && (
                        <td className={thCss}>{item?.areaSqm}</td>
                      )}
                      {dataSource.find((e) => e.name === "filter_bedroom") && (
                        <td className={thCss}>{item?.bedrooms}</td>
                      )}
                      {dataSource.find((e) => e.name === "filter_pool") && (
                        <td className={thCss}>
                          {item?.hasPool ? "Yes" : "No"}
                        </td>
                      )}
                      {dataSource.find(
                        (e) => e.name === "filter_size_exterior"
                      ) && <td className={thCss}>{item?.exterior ? item?.exterior : '-'}</td>}
                      {dataSource.find((e) => e.name === "filter_bathroom") && (
                        <td className={thCss}>{item?.bathrooms}</td>
                      )}
                      {dataSource.find(
                        (e) => e.name === "filter_size_interior"
                      ) && <td className={thCss}>{item?.interior ? item?.interior : '-'}</td>}
                      <td className={thCss}>
                        <img
                          src={icEdit}
                          alt="edit"
                          className="w-15 m-auto"
                          onClick={() => openEditUnitModal(item.id)}
                        />
                      </td>
                      <td className={thCss}>
                        <Modal
                          maskClassName="bg-opacity-50"
                          zIndex="z-[9998]"
                          maskClosable
                          trigger={
                            <span className="text-red-500 cursor-pointer flex justify-center">
                              <TrashIcon width={20} height={20} />
                            </span>
                          }
                          content={({ setIsShow }) => (
                            <div className="bg-white  w-full max-w-md">
                              <h2 className="text-md text-black font-semibold text-center">
                                Are you sure you want to delete this unit?
                              </h2>
                              <div className="flex justify-center mt-6 space-x-4">
                                <Button
                                  disabled={loadingDelete}
                                  className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 text-black"
                                  onClick={() => setIsShow(false)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  disabled={loadingDelete}
                                  isLoading={loadingDelete}
                                  className="bg-yellow-200 text-black px-4 py-2 rounded hover:bg-yellow-300"
                                  onClick={() =>
                                    handleDelete({
                                      onSuccess: () => {
                                        setIsShow(false);
                                        dispatch(reqGetListUnits());
                                      },
                                      onError: () => setIsShow(false),
                                      id: item.id,
                                    })
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          )}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        {/* {isShowUnitModal && <UnitModal unitId={unitId} modalType={modalType} show={isShowUnitModal} setShow={setIsShowUnitModal} />} */}
        {/* {isShowUnitModal && <MediasModal unitId={unitId} show={isShowUnitModal} setShow={setIsShowUnitModal} playListId={playlistId} unitName={unitName} mediaType={modalType} />} */}
        {/* {isShowUnitModal && <UpsertGalleryPlaylist unitId={unitId} show={isShowUnitModal} setShow={setIsShowUnitModal} playListId={playlistId} unitName={unitName} mediaType={modalType} />} */}
        {isShowUnitModal && (
          <Modal
            iconCancelMark={() => (
              <div className="z-[9999] cursor-pointer fixed top-[50px] right-[30px]">
                <img src={closeIcon} alt="close-icon" />
              </div>
            )}
            show={isShowUnitModal}
            setShow={setIsShowUnitModal}
            classCustom="overflow-hidden"
            wrapperClassName="wrap-update-content-modal"
            panelClassName="w-[80vw] p-0 rounded-none bg-[rgb(251,251,251)]"
            zIndex="z-[9998]"
            maskClassName="opacity-90"
            content={({ setIsShow }) => (
              <UpsertGalleryPlaylist
                unitName={unitName}
                setIsShow={setIsShow}
                mediaType={modalType}
                playlistId={playlistId}
                forUnit={true}
                unitId={unitId}
                override={override}
                callbackOnchangePlaylist={onChangePlaylist}
              />
            )}
          />
        )}
        {isShowEditUnitModal && (
          <UnitEditModal
            show={isShowEditUnitModal}
            unitId={unitEditId}
            setShow={setIsShowEditUnitModal}
          />
        )}
        {/* <Modal
          title="Add new unit"
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          maskClosable
          trigger={
            <span className="cursor-pointer bg-gray-100 rounded-lg absolute right-10 bottom-10 cursor-pointer flex justify-center">
              <PlusIcon width={30} height={30}/>
            </span>
          }
          content={({ setIsShow }) => <UnitAddModal setIsShow={setIsShow}/>}
        /> */}
      </div>
    </div>
  );
};

const thCss =
  "text-left border whitespace-nowrap  overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer mt-4 mb-4";

export default UnitData;
