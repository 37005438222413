import _, { get } from "lodash";
import * as yup from "yup";

export const parseResidenceData = (data) => {
  return {
    id: get(data, "id"),
    name: get(data, "name"),
    level: get(data, "level"),
    availabilityStatus: get(data, "availabilityStatus"),
    areaSqm: get(data, "areaSqm"),
    bedrooms: get(data, "bedrooms"),
    bathrooms: get(data, "bathrooms"),
    price: get(data, "price"),
    hasPool: get(data, "hasPool"),
  };
};

export const validationResidenceSchema = yup.object().shape({
  name: yup.string().trim().required("RESIDENCE is required"),
  level: yup.string().trim().required("FLOOR is required"),
  availabilityStatus: yup.string().trim().required("AVAILABILITY is required"),
  areaSqm: yup.number().required("SQ. FT. is required"),
  bedrooms: yup.number().required("BEDS is required"),
  bathrooms: yup.number().required("BATHS is required"),
  price: yup.number().required("PRICE is required"),
  hasPool: yup.string().trim().required("POOL is required"),
});

export const columnMap = {
  filter_status: "Status",
  filter_price: "Price",
  filter_size: "Size",
  filter_bedroom: "Bedroom",
  filter_pool: "Pool",
  filter_size_exterior: "Size exterior",
  filter_bathroom: "Bathroom",
  filter_size_interior: "Size interior",
};

export const getDataUnit = (features) => {
  return features?.length
    ? _.flatMap(features, (feature) =>
        feature.components
          .filter((el) => !!el.props.visible)
          ?.sort((a, b) => a?.props?.order - b?.props?.order)
          ?.map((component) => ({
            ...component,
            nameFeature: feature.name,
            // idModule: moduleState?.id,
          }))
      )?.reduce((acc, curr) => {
        if (
          curr.props?.group &&
          acc.some((item) => item.props?.group === curr.props.group)
        ) {
          return acc;
        }
        return acc.concat(curr);
      }, [])
    : [];
};

function convertToNameValueLabel(data) {
  return _.mapValues(data, (values) => {
    return values.map((value) => {
      if (typeof value === "boolean") {
        return {
          label: value ? "yes" : "no",
          value: String(value),
        };
      }
      return {
        label: String(value),
        value: String(value),
      };
    });
  });
}

export function mergeUnitMetadata(input) {
  const metadataArray = input.map((item) => item.unitMetadata);

  const mergedKeys = _.union(...metadataArray.map((obj) => Object.keys(obj)));

  const result = _.reduce(
    mergedKeys,
    (acc, key) => {
      const allValues = metadataArray.map((obj) => obj[key] || []);
      acc[key] = _.uniq(_.flatten(allValues));
      return acc;
    },
    {}
  );

  return convertToNameValueLabel(result);
}
