/** @format */

import {
  QueueListIcon,
  BuildingOffice2Icon,
  Cog6ToothIcon,
  LockClosedIcon,
  PhotoIcon,
  QuestionMarkCircleIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
  TruckIcon,
  UserGroupIcon,
  UserPlusIcon,
  PaintBrushIcon,
} from '@heroicons/react/24/solid'
import PATH from "./path";
import Index from "../pages/Index";
import NotFoundPage from "../pages/404";
import ForgotPassword from "../pages/auth/forgot-password";
import ResetPassword from "../pages/auth/reset-password";
import Session from "../components/session";
import HoldingPage from "../pages/holding";
import CMS from "../pages/cms";
import Panorama from "../pages/360-pano";
import Presentation from "../pages/presentation";
import CmsFAQs from "../pages/cms/faq";
import CMSUnits from "../pages/cms/units";
import CMSCustomers from "../pages/cms/customers";
import CMSAmenities from "../pages/cms/amenities";
import CMSAssets from "../pages/cms/assets/cms-assets";
import CMSConfiguration from "../pages/cms/configuration";
import { CMSGallery } from "../pages/cms/gallery";
import { CMSPlaylist } from "../pages/cms/playlists";
import CMSVirtualTours from "../pages/cms/virtual-tour";
import CMSTransportOptions from "../pages/cms/transport-options";
import CMSStaffs from "../pages/cms/staffs";
import CMSRolesAccess from "../pages/cms/roles-access/cms-roles-access";
import ForbiddenPage from "../pages/403";
import ForbiddenCMSPage from "../pages/403/cms";
import CMSDefaultList from '../pages/cms/default-list';

export const cmsOptions = [
  {
    key: 'UNITS',
    path: "units",
    element: CMSUnits,
    nav: true,
    ic: BuildingOffice2Icon,
  },
  {
    key: 'AMENITIES',
    path: "amenities",
    element: CMSAmenities,
    nav: true,
    ic: Square3Stack3DIcon,
  },
  {
    key: 'TRANSPORTS',
    path: "transport-options",
    element: CMSTransportOptions,
    nav: true,
    ic: TruckIcon,
  },
  {
    key: 'ACCOUNTS',
    path: "accounts",
    element: CMSStaffs,
    nav: true,
    ic: UserGroupIcon,
  },
  {
    key: 'CUSTOMERS',
    path: "customers",
    element: CMSCustomers,
    nav: true,
    ic: UserPlusIcon,
  },
  {
    key: 'PLAYLISTS',
    path: "playlists",
    element: CMSPlaylist,
    nav: true,
    ic: QueueListIcon,
  },
  {
    key: 'GALLERIES',
    path: "galleries",
    element: CMSGallery,
    nav: true,
    ic: Squares2X2Icon,
  },
  {
    key: 'ASSETS',
    path: "assets",
    element: CMSAssets,
    nav: true,
    ic: PhotoIcon,
  },
  {
    key: 'STATIC_PAGES',
    path: "static",
    element: undefined,
    nav: true,
    ic: PaintBrushIcon,
  },
  {
    key: 'FAQS',
    path: "faqs",
    element: CmsFAQs,
    nav: true,
    ic: QuestionMarkCircleIcon,
  },
  {
    key: 'CONFIGURATIONS',
    path: "configurations",
    element: CMSConfiguration,
    nav: true,
    ic: Cog6ToothIcon,
  },
  {
    key: 'ROLES_ACCESS',
    path: "roles-access",
    element: CMSRolesAccess,
    nav: true,
    ic: LockClosedIcon,
  },
]

export const routeOptions = [
  {
    path: PATH.ROOT,
    name: "Home",
    component: Index,
    exact: true,
  },
  {
    path: PATH.FORGOT_PASSWORD,
    name: "Forgot password",
    component: ForgotPassword,
  },
  {
    path: PATH.RESET_PASSWORD,
    name: "Reset password",
    component: ResetPassword,
  },
  {
    path: PATH.NOTFOUND_PAGE,
    name: "Not found",
    component: NotFoundPage,
  },
  {
    path: PATH.FORBIDDEN_PAGE,
    name: "Forbidden",
    component: ForbiddenPage,
  },
  {
    path: PATH.FORBIDDEN_CMS_PAGE,
    name: "Forbidden",
    component: ForbiddenCMSPage,
  },
  {
    path: PATH.CUSTOMER_SESSION_PAGE,
    name: "customer session",
    component: Session,
  },
  {
    path: PATH.HOLDING_PAGE,
    name: "holding",
    component: HoldingPage,
  },
  {
    path: PATH.CMS_PAGE,
    name: "cms",
    component: CMS,
    subRoutes: [
      { path: "", element: CMSDefaultList, key: '/' },
      { path: "virtual-tours/:virtualId/:modalId", element: CMSVirtualTours },
      ...cmsOptions,
    ],
  },
  {
    path: PATH.PANORAMA_PAGE,
    name: "panorama",
    component: Panorama,
  },
  {
    path: PATH.PRESENTATION_PAGE,
    name: "Presentation Page",
    component: Presentation,
  },
];

export default routeOptions;
