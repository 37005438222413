import React from "react";
import { cn } from "../../../helper/utils";

const CreateIcon = ({ onClick, createCustomer = false, createStaff = false }) => {
  return (
    <div
      className={cn(
        "inline-flex items-center gap-[15px] cursor-pointer h-[130px]",
        {
          "m-0": createCustomer || createStaff,
        }
      )}
      onClick={onClick}
    >
      <img src="/icons/icCreateCircle.svg" alt="" />
      <span className="font-poppins text-[#000] text-[14px] font-bold leading-[21px]">
        {createCustomer
          ? "ADD NEW CUSTOMER"
          : createStaff
            ? "ADD NEW USER"
            : "SUBMIT SUPPORT TICKET"}
      </span>
    </div>
  );
};

export default CreateIcon;
